import { AccountTypeState } from './account-type/account-type.state';
import { HierachyState as HierarchyState } from './hierachy/hierarchy.state';
import { KeyValueSettingState } from './key-value-setting/key-value-setting.state';

export interface AdministrationState {
  accountType: AccountTypeState;
  keyValueSetting: KeyValueSettingState;
  hierarchy: HierarchyState;
}

export const FEATURE_NAME = 'feature/administration';

export const FEATURE_ACTION_PREFIX = 'Feature Administration';
