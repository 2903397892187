import { Injectable, OnDestroy, effect, inject, signal } from '@angular/core';
import {
  AbstractControl,
  FormControl,
  UntypedFormControl,
  UntypedFormGroup,
} from '@angular/forms';
import { createStore, deepFreeze, withProps } from '@ngneat/elf';
import { select } from '@ngrx/store';
import { firstValueFrom, map } from 'rxjs';
import { Router } from '@angular/router';
import { toSignal } from '@angular/core/rxjs-interop';
import {
  ContractsFormDto,
  OperatingHourEntity,
  OrganisationalEntity,
} from './create-ou.types';
import { OrganizationalUnitService } from './organizational-unit.service';
import { CommunicationDetailsDto } from '@swagger/humanresources';

interface IOrganisationalUnit {
  uId: string;
  ouData: OrganisationalEntity;
  operatingHours: OperatingHourEntity[];
  contracts: ContractsFormDto;
  communications: Record<string, CommunicationDetailsDto>;
  errors: Record<string, Record<string, string>>;
  isPristine: boolean;
}

@Injectable({ providedIn: 'root' })
export class CreatedOrganizationalUnitService implements OnDestroy {
  private store = createStore(
    { name: 'administraion-business-create-organisation-unit' },
    withProps<IOrganisationalUnit>({
      uId: null,
      ouData: null,
      operatingHours: [],
      contracts: null,
      communications: {
        internal: null,
        public: null,
      },
      errors: {
        ouData: null,
        operatingHours: null,
        contracts: null,
        requirements: null,
        communications: null,
      },
      isPristine: true,
    })
  );

  private _organisationaUnitService = inject(OrganizationalUnitService);
  private _router = inject(Router);

  $name = signal(null);
  $type = signal(null);

  $operationHoursFormsConter = signal(0);

  $isEditMode = signal(false);

  succesfullySaved = signal(false);

  rootOUs = toSignal(this._organisationaUnitService.organizationalUnits$);

  $unitDetailsForm = signal(null);

  $operatinHoursForm = signal(null);

  $contractsForm = signal(null);

  $communicationDetailsForm = signal(null);

  $initSave = signal(false);

  $initReset = signal(false);

  createdOrganizationalUnitWithErrors$ = this.store.pipe(
    select((state) => {
      return { ou: state.ouData, errors: state.errors.ouData };
    })
  );

  operatingHoursWithErrors$ = this.store.pipe(
    select((state) => {
      return {
        operatingHours: state.operatingHours,
        errors: state.errors.operatingHours,
      };
    })
  );

  communicationDetailsWithErrors$ = this.store.pipe(
    select((state) => {
      return {
        communicationDetails: state.communications,
        errors: state.errors.communications,
      };
    })
  );

  ngOnDestroy(): void {
    this.clear();
  }

  successfullySavedEffect = effect(() => {
    const succesfullySaved = this.succesfullySaved();
    if (succesfullySaved) {
      this.backToOverview();
    }
  });

  backToOverview() {
    const parentUId = this.rootOUs()[0]?.uId;
    if (parentUId) {
      this._router.navigate(['/admin/ou/', parentUId]);
    }
  }

  shouldShowSaveDialog() {
    if (this.succesfullySaved()) {
      return false;
    }

    if (!this.getIsPristine()) {
      return true;
    }

    return false;
  }

  clear() {
    this.store.reset();
    this.$contractsForm.update(() => null);
    this.$operatinHoursForm.update(() => null);
    this.$unitDetailsForm.update(() => null);
    this.$communicationDetailsForm.update(() => null);

    this.$name.update(() => null);
    this.$type.update(() => null);
    this.$operationHoursFormsConter.update(() => 0);
    this.$initSave.update(() => false);
    this.resetEditMode();
  }

  resetEditMode() {
    this.$isEditMode.update(() => false);
  }

  setUid(uId: string) {
    this.store.update((state) => ({
      ...state,
      uId: uId,
    }));
  }

  isConfigureAble(page: string) {
    if (page === 'ouData' || page === 'communications') {
      return true;
    }

    if (
      page === 'operatingHours' &&
      (this.$type() === 'BRANCH' || this.$type() === 'DEPARTMENT')
    ) {
      return true;
    }

    if (
      page === 'contracts' &&
      (this.$type() === 'DEPARTMENT' || this.$type() === 'BRANCH')
    ) {
      return true;
    }

    if (
      page === 'requirements' &&
      (this.$type() === 'DEPARTMENT' ||
        this.$type() === 'BRANCH' ||
        this.$type() === 'PU')
    ) {
      return true;
    }

    return false;
  }

  async loadOrganizationalUnitForEditMode() {
    const organisationalUnit = await firstValueFrom(
      this._organisationaUnitService.activeOrganizationalUnit$.pipe(
        map((ou) => ou)
      )
    );

    const operatingHours = await firstValueFrom(
      this._organisationaUnitService
        .getOperatingHoursFormActiveOU()
        .pipe(map((oh) => oh))
    );

    const contracts = await firstValueFrom(
      this._organisationaUnitService
        .getContractDetailsFromActiveOU()
        .pipe(map((c) => c))
    );

    const communicationDetails = await firstValueFrom(
      this._organisationaUnitService
        .getCommunicationDetailsFromActiveOU()
        .pipe(map((c) => c))
    );
    this.upsertOrganizationalUnit(organisationalUnit);
    this.upsertoperatingHours({ existingOperatingHours: operatingHours });
    this.upsertContracts(contracts);
    this.upsertCommunicationDetails(communicationDetails);
  }

  upsertOrganizationalUnit(ou?: OrganisationalEntity) {
    if (!this._hasValuesInForm(this.$unitDetailsForm()) && !ou) {
      return;
    }
    const organizationalUnit =
      ou !== undefined ? ou : this._createOrganisationalUnitDtoFromForm();

    this.store.update((state) => ({
      ...state,
      ouData: organizationalUnit,
    }));
    this.$name.update(() => organizationalUnit.name);
    this.$type.update(() => organizationalUnit.type);

    let isFormPristine = this.$unitDetailsForm()?.pristine ?? false;
    if (ou !== undefined || !isFormPristine) {
      this.updatePrisitine(this.$unitDetailsForm()?.controls, 'ouData');
    }
  }

  upsertCommunicationDetails(
    existingCommunicationDetails?: Record<string, CommunicationDetailsDto>
  ) {
    if (
      !this._hasValuesInForm(this.$communicationDetailsForm()) &&
      !this.$isEditMode()
    ) {
      return;
    }

    const communicationDetails =
      existingCommunicationDetails ?? this._convertFormToCommunicationDetails();

    this.store.update((state) => ({
      ...state,
      communications: communicationDetails,
    }));

    if (!existingCommunicationDetails) {
      this.updatePrisitine(
        this.$communicationDetailsForm()?.controls,
        'communications'
      );
    }
  }

  upsertoperatingHours({
    createdOperatingHours,
    existingOperatingHours,
  }: {
    createdOperatingHours?: OperatingHourEntity[];
    existingOperatingHours?: OperatingHourEntity[];
  }) {
    let operatingHours = createdOperatingHours ?? [];

    if (existingOperatingHours) {
      operatingHours = existingOperatingHours;
      operatingHours.forEach((oh, index) => {
        oh.dayOfWeekArray = this._mapOperatingHourDayOfWeekArray(
          existingOperatingHours[index]
        );
        oh.uId = existingOperatingHours[index].uId;

        oh.timeStart = new Date(
          existingOperatingHours[index].timeStart
        ).toLocaleTimeString([], {
          hour: '2-digit',
          minute: '2-digit',
        });

        oh.timeStop = new Date(
          existingOperatingHours[index].timeStop
        ).toLocaleTimeString([], {
          hour: '2-digit',
          minute: '2-digit',
        });
      });
    }

    this.store.update((state) => ({
      ...state,
      operatingHours: operatingHours,
    }));

    if (!existingOperatingHours) {
      this.updatePrisitine(
        this.$operatinHoursForm()?.controls,
        'operatingHours'
      );
    }
  }

  upsertContracts(contract?: ContractsFormDto) {
    if (!this._hasValuesInForm(this.$contractsForm())) {
      return;
    }

    const contracts = contract ?? this._createContractsDtoFromForm();

    this.store.update((state) => ({
      ...state,
      contracts: contracts,
    }));

    if (!contract) {
      this.updatePrisitine(this.$contractsForm()?.controls, 'contracts');
    }
  }

  updatePrisitine(controls, page: string) {
    if (controls === undefined) {
      return;
    }

    this.store.update((state) => ({
      ...state,
      isPristine: false,
    }));
    this._setErrors(controls, page);
  }

  getContractsAndErrors() {
    return {
      contacts: this.store.getValue().contracts,
      errors: this.store.getValue().errors.contracts,
    };
  }

  getIsPristine() {
    return this.store.getValue().isPristine;
  }

  async validate() {
    this._setErrors(this.$unitDetailsForm()?.controls, 'ouData');
    if (this.$unitDetailsForm() === null) {
      return;
    }

    const detailFormTypeValue = this.$unitDetailsForm().get('type').value;

    if (
      detailFormTypeValue === 'BRANCH' ||
      detailFormTypeValue === 'DEPARTMENT'
    ) {
      if (this.$operatinHoursForm() !== null) {
        this._setErrors(this.$operatinHoursForm()?.controls, 'operatingHours');
      } else {
        const control = { dayOfWeekArray0: new FormControl({}) };
        control.dayOfWeekArray0.setErrors({ required: true });
        this._setErrors(control, 'operatingHours');
      }
    }
  }

  setControlError(errors: Record<string, string>, form: UntypedFormGroup) {
    for (const key in errors) {
      if (form.controls[key] === undefined) {
        form.addControl(key, new UntypedFormControl({}));
      }

      Object.keys(errors[key]).forEach((error) => {
        form.controls[key]?.setErrors({ [error]: true });
      });
    }

    if (errors !== null) {
      form.markAllAsTouched();
    }
    return form;
  }

  hasErrors() {
    return Object.values(this.store.getValue().errors).some(
      (error) => error !== null
    );
  }

  hasNoValues() {
    const type = this.$type();
    if (type === 'DEPARTMENT' || type === 'BRANCH') {
      return (
        this.store.getValue().ouData === null ||
        this.store.getValue().operatingHours.length === 0 ||
        this.store.getValue().contracts === null ||
        this.store.getValue().communications.internal === null ||
        this.store.getValue().communications.public === null
      );
    }

    return (
      this.store.getValue().ouData === null ||
      this.store.getValue().communications.internal === null ||
      this.store.getValue().communications.public === null
    );
  }

  async saveOrganizationalUnit(currentFormType: string) {
    switch (currentFormType) {
      case 'operatingHours':
        this.upsertoperatingHours({});
        break;
      case 'contracts':
        this.upsertContracts();
        break;
      case 'requirements':
        break;
      case 'ouData':
        this.upsertOrganizationalUnit();
        break;
    }

    this._updateOrCreateOrganisationalUnit();
  }

  getHasErrorsByPage(page: string) {
    return this.store.getValue().errors[page] !== null;
  }

  private _hasValuesInForm(form: AbstractControl) {
    if (form === null) {
      return false;
    }
    return Object.values(form?.value).some(
      (value) => value !== null && value !== undefined
    );
  }

  private async _updateOrCreateOrganisationalUnit() {
    try {
      let ouItem;
      if (this.$isEditMode()) {
        ouItem = await firstValueFrom(this._updateOrganisationalUnit());
      } else {
        ouItem = await firstValueFrom(this._createNewOrganisationalUnit());
      }

      this.succesfullySaved.update(() => true);
      this._organisationaUnitService.resetStore();
    } catch (error) {
      console.error('Error creating Organisational Unit', error);
    }
  }

  private async _hasSameParent(children) {
    const hasSameParent$ =
      await this._organisationaUnitService.organizationalUnits$.pipe(
        map((organisationalUnits) => {
          let childrens = organisationalUnits.filter((organisationalUnit) => {
            return children.some((c) => c.uId === organisationalUnit.uId);
          });

          const parentUIds = childrens.map((c) => c.parent?.uId);
          return new Set(parentUIds).size === 1;
        })
      );

    return await firstValueFrom(hasSameParent$);
  }

  private _mapOperatingHourDayOfWeekArray(oh: OperatingHourEntity) {
    const possibleDays = [512, 256, 64, 32, 16, 8, 4, 2, 1];

    if (oh.dayOfWeek === 0) {
      return [];
    }

    const dayOfWeekArray = [];
    let dayOfWeek = oh.dayOfWeek;
    possibleDays.forEach((day) => {
      if (dayOfWeek >= day) {
        dayOfWeekArray.push(day.toString());
        dayOfWeek -= day;
      }
    });

    return dayOfWeekArray;
  }

  private _setErrors(
    controls: { [key: string]: AbstractControl },
    page: string
  ) {
    const errors = {};
    if (controls) {
      Object.keys(controls).forEach((key) => {
        if (controls[key]?.errors) {
          errors[key] = controls[key]?.errors;
        }
      });
    }

    this.store.update((state) => ({
      ...state,
      errors: {
        ...state.errors,
        [page]: Object.keys(errors).length > 0 ? errors : null,
      },
    }));
  }

  private _communicationDetailsNotNull() {
    return (
      this.store.getValue().communications.internal !== null &&
      this.store.getValue().communications.public !== null
    );
  }

  private _createOrganisationalUnitDtoFromForm(): OrganisationalEntity {
    let children = [],
      parent: {} | string = '';
    const formValues = this.$unitDetailsForm().value;
    const contracts = this.getContractsAndErrors().contacts;

    if (formValues.children !== null && formValues.children?.length > 0) {
      formValues.children.map((c) => {
        children.push({ uId: c });
      });
    }

    if (formValues.parent !== null) {
      parent = { uId: formValues.parent };
    }

    return {
      name: formValues.name,
      shortName: formValues.shortName,
      children: children,
      start: formValues.start,
      stop: new Date('01-01-2100'),
      parent: parent,
      key: '',
      type: formValues.type,
      description: formValues.desc,
      holidayCalendar: contracts?.holidayCalendar,
      organizationalUnitCode: formValues.costUnit,
      operatingHours: [],
      sort: 0,
    };
  }

  private _createContractsDtoFromForm(): ContractsFormDto {
    const formValues = this.$contractsForm().value;

    return {
      holidayCalendar: formValues.holidayCalendar,
    };
  }

  private _createNewOrganisationalUnit() {
    const ouForm = this.store.getValue().ouData;
    if (this._communicationDetailsNotNull()) {
      ouForm.communicationDetailsInternal =
        this.store.getValue().communications.internal;
      ouForm.communicationDetailsPublic =
        this.store.getValue().communications.public;
    }

    const updatedOus = [];
    return this._organisationaUnitService.createOrganisationalUnit(ouForm).pipe(
      map(async (organisation) => {
        if (ouForm.children.length > 0) {
          updatedOus.concat(await this._updateChildren(ouForm, organisation));
        }

        const operatingHourDtos = this.store
          .getValue()
          .operatingHours.filter((oh) => oh.dayOfWeek !== 0);
        organisation.operatingHours = [];
        operatingHourDtos.forEach(async (oh) => {
          oh.ou = { uId: organisation.uId };
          organisation.operatingHours.push(
            await firstValueFrom(
              this._organisationaUnitService.createOperatingHour(
                organisation.uId,
                oh
              )
            )
          );
        });

        return organisation;
      })
    );
  }

  private _updateOrganisationalUnit() {
    const uId = this._organisationaUnitService.getActiveUId();
    const ou = this.store.getValue().ouData;
    const updatedOus = [];

    if (this._communicationDetailsNotNull()) {
      ou.communicationDetailsInternal =
        this.store.getValue().communications.internal;
      ou.communicationDetailsPublic =
        this.store.getValue().communications.public;
    }
    ou.uId = uId;
    return this._organisationaUnitService
      .updateOrganisationalUnit(uId, ou)
      .pipe(
        map(async (organisation) => {
          if (ou.children.length > 0) {
            updatedOus.concat(await this._updateChildren(ou, organisation));
          }
          const operatingHourDtos = this.store
            .getValue()
            .operatingHours.filter((oh) => oh.dayOfWeek !== 0);
          organisation.operatingHours = [];
          operatingHourDtos.forEach(async (oh) => {
            oh.ou = { uId: organisation.uId };
            if (oh.uId) {
              organisation.operatingHours.push(
                await firstValueFrom(
                  this._organisationaUnitService.updateOperatingHour(
                    organisation.uId,
                    oh
                  )
                )
              );
            } else {
              organisation.operatingHours.push(
                await firstValueFrom(
                  this._organisationaUnitService.createOperatingHour(
                    organisation.uId,
                    oh
                  )
                )
              );
            }
          });

          return organisation;
        })
      );
  }

  private async _updateChildren(ou, newOrUpdatedOu) {
    const updatedOus = [];
    if (await this._hasSameParent(ou.children)) {
      const childParentUId = await firstValueFrom(
        this._organisationaUnitService.getParentUIdFromUId(ou.children[0].uId)
      );
      const moveedChildren = await firstValueFrom(
        this._organisationaUnitService.moveChildren(
          childParentUId,
          newOrUpdatedOu.uId
        )
      );
    } else {
      const filteredChildren = ou.children.filter(
        (child) => child.uId !== newOrUpdatedOu.uId
      );
      filteredChildren.forEach(async (child) => {
        updatedOus.push(
          await firstValueFrom(
            this._organisationaUnitService.updateOrganisationalUnit(child.uId, {
              uId: child.uId,
              parent: { uId: newOrUpdatedOu.uId },
            })
          )
        );
      });
    }
    return updatedOus;
  }

  private _convertFormToCommunicationDetails(): Record<
    string,
    CommunicationDetailsDto
  > {
    const formValues = this.$communicationDetailsForm().value;

    const internalDto: CommunicationDetailsDto = {};
    const publicDto: CommunicationDetailsDto = {};

    Object.keys(formValues).filter((key) => {
      if (key.includes('internal') && key !== 'headline-internal') {
        const newKey = key.replace('-internal', '');
        internalDto[newKey] = formValues[key];
      }

      if (key.includes('public') && key !== 'headline-public') {
        const newKey = key.replace('-public', '');
        publicDto[newKey] = formValues[key];
      }
    });

    return {
      internal: internalDto,
      public: publicDto,
    };
  }
}
