import { createReducer, on } from '@ngrx/store';
import { hierachyAdapter, hierachyInitialState } from './hierarchy.state';
import {
  createHierarchy,
  fetchHierarchies,
  fetchHierarchy,
} from './hierarchy.actions';

export const hierarchyReducer = createReducer(
  hierachyInitialState,
  on(fetchHierarchies.request, (state) => ({ ...state, loadingList: true })),
  on(fetchHierarchies.success, (state, { hierarchies }) => {
    let nextState = hierachyAdapter.removeAll(state);
    nextState = hierachyAdapter.addMany(hierarchies, nextState);
    return { ...nextState, loadingList: false };
  }),
  on(fetchHierarchies.failure, (state, { error }) => ({
    ...state,
    loadingList: false,
  })),
  on(fetchHierarchy.request, (state, { uId }) => ({
    ...state,
    loadingEntity: { ...state.loadingEntity, [uId]: true },
  })),
  on(fetchHierarchy.success, (state, { uId, hierarchy }) => {
    const nextState = {
      ...state,
      loadingEntity: { ...state.loadingEntity, [uId]: false },
    };
    if (hierarchy === null) {
      return nextState;
    }
    return hierachyAdapter.upsertOne(hierarchy, nextState);
  }),
  on(fetchHierarchy.failure, (state, { uId, error }) => ({
    ...state,
    loadingEntity: { ...state.loadingEntity, [uId]: false },
  })),

  on(createHierarchy.upsertHierachy, (state, { hierarchy }) => {
    const nextState = {
      ...state,
      loadingEntity: { ...state.loadingEntity, [hierarchy.uId]: false },
    };
    return hierachyAdapter.upsertOne(hierarchy, nextState);
  })
);
