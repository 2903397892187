import moment from 'moment';
import { SettingServiceTypes } from './setting.types';

export class ValidationError extends Error {
  constructor(
    public readonly property: string,
    public readonly value: any,
    message: string
  ) {
    super(message);
  }
}

export class SettingValidators {
  private constructor() {}

  static validateAddSetting({
    settingGroupUId,
    add,
  }: SettingServiceTypes.AddSetting): void {
    if (!settingGroupUId) {
      throw new ValidationError(
        'settingGroupUId',
        settingGroupUId,
        'Setting group UId is required'
      );
    }

    if (!add) {
      throw new ValidationError('add', add, 'Add is required');
    }

    if (!add.name) {
      throw new ValidationError('add.name', add.name, 'Name is required');
    }
    if (typeof add.name !== 'string') {
      throw new ValidationError('add.name', add.name, 'Name must be a string');
    }

    if (!add.key) {
      throw new ValidationError('add.key', add.key, 'Key is required');
    }
    if (typeof add.key !== 'string') {
      throw new ValidationError('add.key', add.key, 'Key must be a string');
    }

    if (typeof add.validFrom !== 'string') {
      throw new ValidationError(
        'add.validFrom',
        add.validFrom,
        'Valid from is required as a ISO string'
      );
    }
    if (!moment(add.validFrom).isValid()) {
      throw new ValidationError(
        'add.validFrom',
        add.validFrom,
        'Valid from is not a valid date'
      );
    }

    if (add.validTo) {
      if (typeof add.validTo !== 'string') {
        throw new ValidationError(
          'add.validTo',
          add.validTo,
          'Valid to is required as a ISO string'
        );
      }

      if (!moment(add.validTo).isValid()) {
        throw new ValidationError(
          'add.validTo',
          add.validTo,
          'Valid to is not a valid date'
        );
      }
      if (!moment(add.validTo).isAfter(add.validFrom)) {
        throw new ValidationError(
          'add.validTo',
          add.validTo,
          'Valid to must be after valid from'
        );
      }
    }

    return null;
  }

  static validateUpdateSetting({
    settingUId,
    update,
  }: SettingServiceTypes.UpdateSetting): void {
    if (!settingUId) {
      throw new ValidationError(
        'settingUId',
        settingUId,
        'Setting UId is required'
      );
    }

    if (!update) {
      throw new ValidationError('update', update, 'Update is required');
    }

    if (!update.name) {
      throw new ValidationError('update.name', update.name, 'Name is required');
    }
    if (typeof update.name !== 'string') {
      throw new ValidationError('update.name', update.name, 'Name must be a string');
    }

    if (!update.key) {
      throw new ValidationError('update.key', update.key, 'Key is required');
    }
    if (typeof update.key !== 'string') {
      throw new ValidationError('update.key', update.key, 'Key must be a string');
    }

    if (typeof update.validFrom !== 'string') {
      throw new ValidationError(
        'update.validFrom',
        update.validFrom,
        'Valid from is required as a ISO string'
      );
    }
    if (!moment(update.validFrom).isValid()) {
      throw new ValidationError(
        'update.validFrom',
        update.validFrom,
        'Valid from is not a valid date'
      );
    }

    if (update.validTo) {
      if (typeof update.validTo !== 'string') {
        throw new ValidationError(
          'update.validTo',
          update.validTo,
          'Valid to is required as a ISO string'
        );
      }

      if (!moment(update.validTo).isValid()) {
        throw new ValidationError(
          'update.validTo',
          update.validTo,
          'Valid to is not a valid date'
        );
      }
      if (!moment(update.validTo).isAfter(update.validFrom)) {
        throw new ValidationError(
          'update.validTo',
          update.validTo,
          'Valid to must be after valid from'
        );
      }
    }

    return null;
  }

  static validateAddSettingValues(
    params: SettingServiceTypes.AddSettingValues
  ): void {
    const { settingUId, add } = params;

    if (!settingUId) {
      throw new ValidationError(
        'settingUId',
        settingUId,
        'Setting UId is required'
      );
    }

    if ('metadata' in params) {
      const metadata = params.metadata;
      if (!Array.isArray(metadata)) {
        throw new ValidationError(
          'metadata',
          metadata,
          'Metadata must be an array'
        );
      }
    } else if ('settingGroupUId' in params) {
      if (!params.settingGroupUId) {
        throw new ValidationError(
          'settingGroupUId',
          params.settingGroupUId,
          'Setting group UId is required'
        );
      }
    } else {
      throw new ValidationError(
        'params',
        params,
        'Params must contain either metadata or settingGroupUId'
      );
    }

    try {
      for (let value of add) {
        SettingValidators.validateAddSettingValue({
          ...params,
          add: value,
        });
      }
    } catch (error) {
      if (error instanceof ValidationError) {
        throw new ValidationError(
          'add.' + error.property,
          error.value,
          error.message
        );
      }

      throw error;
    }
  }

  static validateAddSettingValue(
    params: SettingServiceTypes.AddSettingValue
  ): void {
    const { settingUId, add } = params;

    if (!settingUId) {
      throw new ValidationError(
        'settingUId',
        settingUId,
        'Setting UId is required'
      );
    }

    if ('metadata' in params) {
      const metadata = params.metadata;
      if (!Array.isArray(metadata)) {
        throw new ValidationError(
          'metadata',
          metadata,
          'Metadata must be an array'
        );
      }
    } else if ('settingGroupUId' in params) {
      const settingGroupUId = params.settingGroupUId;
      if (!settingGroupUId) {
        throw new ValidationError(
          'settingGroupUId',
          settingGroupUId,
          'Setting group UId is required'
        );
      }
    } else {
      throw new ValidationError(
        'params',
        params,
        'Params must contain either metadata or settingGroupUId'
      );
    }

    if (!add) {
      throw new ValidationError('add', add, 'Add is required');
    }
    if (!add.value) {
      throw new ValidationError('add.value', add.value, 'Value is required');
    }
    if (typeof add.value !== 'string') {
      throw new ValidationError(
        'add.value',
        add.value,
        `Value must be a string but is ${typeof add.value}`
      );
    }
    if (!add.ref) {
      throw new ValidationError('add.ref', add.ref, 'Ref is required');
    }
    if (typeof add.ref !== 'string') {
      throw new ValidationError('add.ref', add.ref, 'Ref must be a string');
    }

    if (add.validFrom) {
      if (typeof add.validFrom !== 'string') {
        throw new ValidationError(
          'add.validFrom',
          add.validFrom,
          'Valid from is required as a ISO string'
        );
      }

      if (!moment(add.validFrom).isValid()) {
        throw new ValidationError(
          'add.validFrom',
          add.validFrom,
          'Valid from is not a valid date'
        );
      }
    }

    if (add.validTo) {
      if (!moment(add.validTo).isValid()) {
        throw new ValidationError(
          'add.validTo',
          add.validTo,
          'Valid to is not a valid date'
        );
      }
      if (!moment(add.validTo).isBefore(add.validFrom)) {
        throw new ValidationError(
          'add.validTo',
          add.validTo,
          'Valid to must be after valid from'
        );
      }
    }
  }
}
