import {
  AffectedAccountTypeDto,
  ScheduleItemTypeDto,
  TimeInterval,
} from '@swagger/humanresources';
import moment from 'moment';

export class ValidationError extends Error {
  constructor(
    public readonly property: string,
    public readonly value: any,
    message: string
  ) {
    super(message);
  }
}

export class ScheduleItemTypeValidators {
  private constructor() {}

  static validateScheduleItemTypeDtoAndAffectedAccountTypeDto({
    scheduleItemType,
    update,
  }: {
    scheduleItemType: ScheduleItemTypeDto;
    update?: boolean;
  }): void {
    if (!!update && !scheduleItemType.uId) {
      throw new ValidationError(
        'scheduleItemTypeDtoUid',
        scheduleItemType?.uId,
        'Schedule Item Type uId is required'
      );
    }

    if (!scheduleItemType?.key) {
      throw new ValidationError(
        'key',
        scheduleItemType?.key,
        'Key is required'
      );
    }

    if (!scheduleItemType?.name) {
      throw new ValidationError(
        'name',
        scheduleItemType?.name,
        'Name is required'
      );
    }

    if (
      !!scheduleItemType?.timeInterval &&
      !(scheduleItemType?.timeInterval in TimeInterval)
    ) {
      throw new ValidationError(
        'timeInterval',
        scheduleItemType?.timeInterval,
        'TimeInterval must be a valid TimeInterval'
      );
    }

    for (const affectedAccountType of scheduleItemType?.affectedAccountTypes) {
      this.validateAffectedAccountTypeDto(affectedAccountType?.data);
    }

    return null;
  }

  static validateAffectedAccountTypeDto(
    affectedAccountType: AffectedAccountTypeDto
  ): void {
    if (affectedAccountType?.bookingType !== null) {
      if (typeof affectedAccountType?.start !== 'string') {
        throw new ValidationError(
          'start',
          affectedAccountType?.start,
          'Start is required'
        );
      }
      if (!moment(affectedAccountType?.start).isValid()) {
        throw new ValidationError(
          'start',
          affectedAccountType?.start,
          'Start is not a valid date'
        );
      }

      if (typeof affectedAccountType?.stop !== 'string') {
        throw new ValidationError(
          'stop',
          affectedAccountType?.stop,
          'Stop is required'
        );
      }
      if (!moment(affectedAccountType?.stop).isValid()) {
        throw new ValidationError(
          'stop',
          affectedAccountType?.stop,
          'Stop is not a valid date'
        );
      }

      if (
        !moment(affectedAccountType?.stop).isAfter(affectedAccountType?.start)
      ) {
        throw new ValidationError(
          'affectedAccoutType?.data?.stop',
          affectedAccountType?.stop,
          'Stop must be after Start'
        );
      }
    }
  }
}
