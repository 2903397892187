import { createReducer, on } from '@ngrx/store';
import {
  accountTypeInitialState,
  accountTypeAdapter,
} from './account-type.state';
import { fetchAccountType, fetchAccountTypes } from './account-type.actions';

export const accountTypeReducer = createReducer(
  accountTypeInitialState,
  on(fetchAccountTypes.request, (state) => ({ ...state, loadingList: true })),
  on(fetchAccountTypes.success, (state, { accountTypes }) => {
    let nextState = accountTypeAdapter.removeAll(state);
    nextState = accountTypeAdapter.addMany(accountTypes, nextState);
    return { ...nextState, loadingList: false };
  }),
  on(fetchAccountTypes.failure, (state, { error }) => ({
    ...state,
    loadingList: false,
  })),
  on(fetchAccountType.request, (state, { uId }) => ({ ...state, loadingEntity: { ...state.loadingEntity, [uId]: true } })),
  on(fetchAccountType.success, (state, { uId, accountType }) => {
    const nextState = {...state, loadingEntity: { ...state.loadingEntity, [uId]: false }};
    if (accountType === null) {
      return nextState
    }
    return accountTypeAdapter.upsertOne(accountType, nextState);
  }),
  on(fetchAccountType.failure, (state, { uId, error }) => ({ ...state, loadingEntity: { ...state.loadingEntity, [uId]: false }}))
);
