import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType, OnInitEffects } from '@ngrx/effects';
import { fetchHierarchies, fetchHierarchy } from './hierarchy.actions';
import { HierarchyService } from '@swagger/humanresources';
import { catchError, debounceTime, map, switchMap } from 'rxjs';
import { HierarchyHelperService } from './hierarchy-helper.service';

@Injectable()
export class HierarchyEffects implements OnInitEffects {
  private _actions$ = inject(Actions);
  private _api = inject(HierarchyService);
  private _helperService = inject(HierarchyHelperService);

  fetchHierarchies$ = createEffect(() =>
    this._actions$.pipe(
      ofType(fetchHierarchies.request),
      debounceTime(1000),
      switchMap(() =>
        this._api.hierarchyQueryHierarchy({ body: {} }).pipe(
          map((response) =>
            fetchHierarchies.success({
              hierarchies: this._helperService.flattenUnits(response.result),
            })
          ),
          catchError((error) => [fetchHierarchies.failure({ error })])
        )
      )
    )
  );

  fetchHierarchy$ = createEffect(() =>
    this._actions$.pipe(
      ofType(fetchHierarchy.success),
      switchMap(({ uId }) => {
        return this._api.hierarchyGetHierarchy({ hierarchyUId: uId }).pipe(
          map((response) =>
            fetchHierarchy.success({
              uId,
              hierarchy: response.result,
            })
          ),
          catchError((error) => [fetchHierarchy.failure({ uId, error })])
        );
      })
    )
  );

  ngrxOnInitEffects() {
    return fetchHierarchies.request();
  }
}
