import { Injectable, effect, inject, signal, untracked } from '@angular/core';
import { HierarchyDto } from '@swagger/humanresources';
import { firstValueFrom, map } from 'rxjs';
import { HierarchyStore } from '../../store/hierachy/hierarchy.store';
import { toSignal } from '@angular/core/rxjs-interop';
import { HierarchyRespositoryService } from './hierarchy.repository';

@Injectable({
  providedIn: 'root',
})
export class HierachyService {
  private _hierachyRepository = inject(HierarchyRespositoryService);
  private _hierarchyStore = inject(HierarchyStore);

  $fetching = signal(false);
  hierachyUnits$ = this._hierarchyStore.hierarchies$;

  $hierarchyUnits = toSignal(this.hierachyUnits$);

  rootHierachies$ = this._hierarchyStore.rootUnits$;

  $isEditMode = signal(false);

  $successFullySaved = signal(false);

  $activeHierarchyUId = signal<string | null>(null);

  getHierachyUnitsForParentUId(parentUId: string) {
    return this._hierarchyStore.hierarchiesByParentUId$(parentUId);
  }

  getParentFromUId(uId: string) {
    return this._hierarchyStore.parentByChildUId$(uId);
  }

  getHierachylUnitByUId(uId: string) {
    return this._hierarchyStore.hierarchyByUId$(uId);
  }

  getActiveHierarchy() {
    return this._hierarchyStore.hierarchyByUId$(this.$activeHierarchyUId());
  }

  getDisplayLabelForPArentOrChild(uId: string) {
    return this._hierarchyStore.displayLabelForParentOrChild$(uId);
  }

  upsertHierachietoStore(hierachies: HierarchyDto[]) {
    hierachies.forEach((hierachy) => {
      this._hierarchyStore.upsertHierachy(hierachy);
    });
  }

  async updateOrCreateHierachy(hierachy: HierarchyDto) {
    let hierachyItem: HierarchyDto;
    if (this.$isEditMode()) {
      try {
        hierachyItem = await firstValueFrom(
          this.updateHierachy(hierachy.uId, hierachy)
        );
      } catch (e) {
        console.error(e);
        throw new Error(e);
      }
    } else {
      try {
        hierachyItem = await firstValueFrom(this.createHierachy(hierachy));
      } catch (e) {
        console.error(e);
        throw new Error(e);
      }
    }

    this._hierarchyStore.upsertHierachy(hierachyItem);
    this.updateParentToStore(hierachy.parent.uId, hierachyItem.uId);
    this.$successFullySaved.update(() => true);
  }

  private async updateParentToStore(parentUId: string, childUId: string) {
    const parent = await firstValueFrom(
      this.getHierachylUnitByUId(parentUId).pipe(
        map((p) => {
          const children = [...p.children, { uId: childUId }];
          return {
            ...p,
            children: children,
            hasChildren: 1,
          };
        })
      )
    );

    this._hierarchyStore.upsertHierachy(parent);
  }

  private createHierachy(hierachy: HierarchyDto) {
    const createdHierachies = [];

    return this._hierachyRepository.createHierachy(hierachy).pipe(
      map((createdHierachy) => {
        if (hierachy.children?.length > 0) {
          createdHierachies.concat(
            this._updateChildren(hierachy, createdHierachy)
          );
        }
        return createdHierachy;
      })
    );
  }

  private updateHierachy(uId: string, unit: HierarchyDto) {
    const updatedHierarchies = [];

    return this._hierachyRepository.updateHierachy(uId, unit).pipe(
      map(async (updatedHierarchy) => {
        if (unit.children?.length > 0) {
          updatedHierarchies.concat(
            await this._updateChildren(unit, updatedHierarchy)
          );
        }

        return updatedHierarchy;
      })
    );
  }

  private _hasSameParent(children) {
    const fullChildrenDto = this.$hierarchyUnits().filter((hierachy) => {
      return children.some((c) => c.uId === hierachy.uId);
    });

    const parentUIds = fullChildrenDto.map((c) => c.parent?.uId);

    return new Set(parentUIds).size === 1;
  }

  private async _updateChildren(
    hierachyDtoFromForm: HierarchyDto,
    newOrUpdatedHierarchy: HierarchyDto
  ) {
    const updatedUnits = [];

    if (this._hasSameParent(hierachyDtoFromForm.children)) {
      const childParentUId = await firstValueFrom(
        this.getParentFromUId(hierachyDtoFromForm.children[0].uId)
      );

      if (childParentUId) {
        const movedChildren = await firstValueFrom(
          this._hierachyRepository.moveChildren(
            childParentUId.uId,
            newOrUpdatedHierarchy.uId
          )
        );
        console.log(movedChildren);
      }
    } else {
      const filteredChildren = hierachyDtoFromForm.children.filter(
        (c) => c.uId !== newOrUpdatedHierarchy.uId
      );

      filteredChildren.forEach(async (child) => {
        updatedUnits.push(
          await firstValueFrom(
            this._hierachyRepository.updateHierachy(child.uId, {
              uId: child.uId,
              parent: { uId: newOrUpdatedHierarchy.uId },
            })
          )
        );
      });
    }

    return updatedUnits;
  }
}
