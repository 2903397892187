import { createSelector } from '@ngrx/store';
import { selectAdministrationFeature } from '../administration.selectors';
import { hierachyAdapter } from './hierarchy.state';
import { HierarchyDto } from '@swagger/humanresources';

export const selectHierarchyState = createSelector(
  selectAdministrationFeature,
  (s) => s.hierarchy
);

const { selectAll, selectEntities } =
  hierachyAdapter.getSelectors(selectHierarchyState);

export const selectHierarchies = createSelector(selectAll, (entities) =>
  entities.sort((a, b) => a.name.localeCompare(b.name))
);

export const selectLoadingList = createSelector(
  selectHierarchyState,
  (s) => s.loadingList
);

export const selectLoadingEntity = createSelector(
  selectHierarchyState,
  (s) => s.loadingEntity
);

export const selectHierarchyById = (uId: string) =>
  createSelector(selectEntities, (entities) => entities[uId]);

export const rootHierachies = createSelector(selectAll, (hierarchies) =>
  hierarchies.filter((h) => {
    if (!h.parent) {
      return true;
    }
    if (!hierarchies.some((e) => e.uId === h.parent.uId)) {
      return true;
    }

    return false;
  })
);

export const selectHierarchiesForParentUId = (parentUId: string) =>
  createSelector(selectAll, (hierarchies) => {
    const hierachy: HierarchyDto[] = [];
    const parent = hierarchies.find((h) => h.uId === parentUId);

    if (!parent) {
      return hierachy;
    }

    function traverse(hierachyEntity: HierarchyDto) {
      hierachy.push(hierachyEntity);
      hierachyEntity.children?.forEach((c) => {
        const child = hierarchies.find((e) => e.uId === c.uId);
        if (child) {
          traverse(child);
        }
      });
    }
    traverse(parent);
    return hierachy;
  });

export const selectParentFromChildUId = (uId: string) =>
  createSelector(selectAll, (hierarchies) => {
    const child = hierarchies.find((h) => h.uId === uId);
    if (!child) {
      return null;
    }

    return hierarchies.find((h) => h.uId === child.parent?.uId);
  });
