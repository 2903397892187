import { AccountTypeDto } from "@swagger/humanresources";
import moment from "moment";

export function validateDates(accountType: AccountTypeDto) {
    if(!accountType) {
        throw new Error('Account type is required');
    }

    if(!accountType.start) {
        throw new Error('Start date is required');
    }

    if(moment(accountType.start).isBefore(moment())) {
        throw new Error('Start date cannot be in the past');
    }

    if(accountType.stop && moment(accountType.stop).isBefore(moment(accountType.start))) {
        throw new Error('Stop date cannot be before start date');
    }

    return true;    
}

export function validateAccountTypeDto(accountType: AccountTypeDto) {
    if(!accountType) {
        throw new Error('Account type is required');
    }

    if(!accountType.name) {
        throw new Error('Name is required');
    }

    if(!accountType.key) {
        throw new Error('Key is required');
    }

    if(!accountType.quantityUnit) {
        throw new Error('Quantity unit is required');
    }

    if(!accountType.quantityUnitType) {
        throw new Error('Quantity unit type is required');
    }

    validateDates(accountType);

    return true;
}