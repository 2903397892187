import { createAction, createActionGroup, emptyProps, props } from '@ngrx/store';
import { FEATURE_ACTION_PREFIX } from '../administration.state';
import { Setting, SettingGroup, SettingValueMetadata } from '@graphql/hrp-settings';

const ACTION_PREFIX = `${FEATURE_ACTION_PREFIX} Key Value Setting`;

export const initKeyValueSetting = createAction(`${ACTION_PREFIX} Init Key Value Setting`)

export const fetchSettingGroups = createActionGroup({
  source: `${ACTION_PREFIX} Fetch Setting Groups`,
  events: {
    Request: emptyProps(),
    Success: props<{ settingGroups: SettingGroup[] }>(),
    Failure: props<{ error: any }>(),
  },
});

export const fetchSettingGroup = createActionGroup({
  source: `${ACTION_PREFIX} Fetch Setting Group`,
  events: {
    Request: props<{ uId: string }>(),
    Success: props<{ uId: string; settingGroup: SettingGroup | null }>(),
    Failure: props<{ uId: string; error: any }>(),
  },
});

export const fetchValueMetadata = createActionGroup({
  source: `${ACTION_PREFIX} Fetch Value Metadata`,
  events: {
    Request: emptyProps(),
    Success: props<{ metadata: SettingValueMetadata[] }>(),
    Failure: props<{ error: any }>(),
  },
});

export const fetchSingleValueMetadata = createActionGroup({
  source: `${ACTION_PREFIX} Fetch Single Value Metadata`,
  events: {
    Request: props<{ groupUId: string; uId: string }>(),
    Success: props<{ uId: string; settingMetadata: SettingValueMetadata | null }>(),
    Failure: props<{ uId: string; error: any }>(),
  },
});

export const fetchSettings = createActionGroup({
  source: `${ACTION_PREFIX} Fetch Settings`,
  events: {
    Request: emptyProps(),
    Success: props<{ settings: Setting[] }>(),
    Failure: props<{ error: any }>(),
  },
});

export const fetchSetting = createActionGroup({
  source: `${ACTION_PREFIX} Fetch Setting`,
  events: {
    Request: props<{ groupUId: string; uId: string }>(),
    Success: props<{ uId: string; setting: Setting | null }>(),
    Failure: props<{ uId: string; error: any }>(),
  },
});

export const fetchSettingByGroupUIdAndKey = createActionGroup({
  source: `${ACTION_PREFIX} Fetch Setting By Group UId And Key`,
  events: {
    Request: props<{ groupUId: string; key: string }>(),
    Success: props<{ key: string; settings: Setting[] }>(),
    Failure: props<{ key: string; error: any }>(),
  },
});

export const removeSettingByUId = createAction(`${ACTION_PREFIX} Remove Setting By UId`, props<{ uId: string }>());