import { inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  rootHierachies,
  selectHierarchies,
  selectHierarchiesForParentUId,
  selectHierarchyById,
  selectParentFromChildUId,
} from './hierarchy.selectors';
import {
  createHierarchy,
  fetchHierarchies,
  fetchHierarchy,
} from './hierarchy.actions';
import { HierarchyDto } from '@swagger/humanresources';
import { map } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class HierarchyStore {
  private _store = inject(Store);

  hierarchies$ = this._store.select(selectHierarchies);

  rootUnits$ = this._store.select(rootHierachies);

  hierarchyByUId$ = (uId: string) =>
    this._store.select(selectHierarchyById(uId));

  hierarchiesByParentUId$ = (parentUId: string) =>
    this._store.select(selectHierarchiesForParentUId(parentUId));

  parentByChildUId$ = (childUId: string) =>
    this._store.select(selectParentFromChildUId(childUId));

  fetchHierarchies() {
    this._store.dispatch(fetchHierarchies.request());
  }

  fetchHierarchy(uId: string) {
    this._store.dispatch(fetchHierarchy.request({ uId }));
  }

  upsertHierachy(hierarchy: HierarchyDto) {
    this._store.dispatch(createHierarchy.upsertHierachy({ hierarchy }));
  }

  displayLabelForParentOrChild$(uId: string) {
    return this.hierarchyByUId$(uId).pipe(map((hierarchy) => hierarchy?.name));
  }
}
