import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { FEATURE_ACTION_PREFIX } from '../administration.state';
import { AccountTypeDto } from '@swagger/humanresources';

const ACTION_PREFIX = `${FEATURE_ACTION_PREFIX} Account Type`;

export const fetchAccountTypes = createActionGroup({
  source: `${ACTION_PREFIX} Fetch Account Types`,
  events: {
    Request: emptyProps(),
    Success: props<{ accountTypes: AccountTypeDto[] }>(),
    Failure: props<{ error: any }>(),
  },
});

export const fetchAccountType = createActionGroup({
  source: `${ACTION_PREFIX} Fetch Account Type`,
  events: {
    Request: props<{ uId: string }>(),
    Success: props<{ uId: string; accountType: AccountTypeDto | null }>(),
    Failure: props<{ uId: string; error: any }>(),
  },
});
