import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { FEATURE_ACTION_PREFIX } from '../administration.state';
import { HierarchyDto } from '@swagger/humanresources';

const ACTION_PREFIX = `${FEATURE_ACTION_PREFIX} Hierarchy`;

export const fetchHierarchies = createActionGroup({
  source: `${ACTION_PREFIX} Fetch Hierarchies`,
  events: {
    Request: emptyProps(),
    Success: props<{ hierarchies: HierarchyDto[] }>(),
    Failure: props<{ error: any }>(),
  },
});

export const fetchHierarchy = createActionGroup({
  source: `${ACTION_PREFIX} Fetch Hierarchy`,
  events: {
    Request: props<{ uId: string }>(),
    Success: props<{ uId: string; hierarchy: HierarchyDto | null }>(),
    Failure: props<{ uId: string; error: any }>(),
  },
});

export const createHierarchy = createActionGroup({
  source: `${ACTION_PREFIX} Create Hierarchy`,
  events: {
    upsertHierachy: props<{ hierarchy: HierarchyDto }>(),
  },
});
