import { createSelector } from '@ngrx/store';
import { selectAdministrationFeature } from '../administration.selectors';
import {
  groupsAdapter,
  settingsAdapter,
  metadataAdapter,
} from './key-value-setting.state';
import { EntityStatus, Setting } from '@graphql/hrp-settings';
import moment from 'moment';
import { MAX_DATE, MIN_DATE } from '@shared/constants';


export const keyValueSettingState = createSelector(
  selectAdministrationFeature,
  (s) => s.keyValueSetting
);

export const selectSettingGroupState = createSelector(
  keyValueSettingState,
  (s) => s.groups
);

export const selectSettingState = createSelector(
  keyValueSettingState,
  (s) => s.settings
);

export const selectMetadataState = createSelector(
  keyValueSettingState,
  (s) => s.metadata
);

const settingGroupAdapterSelectors = groupsAdapter.getSelectors(
  selectSettingGroupState
);

const settingsAdapterSelectors =
  settingsAdapter.getSelectors(selectSettingState);

const metadataAdapterSelectors =
  metadataAdapter.getSelectors(selectMetadataState);

export const selectAllSettingGroups = createSelector(
  settingGroupAdapterSelectors.selectAll,
  (state) => state.filter((g) => g.status === EntityStatus.Online)
);

export const selectAllSettings = createSelector(
  settingsAdapterSelectors.selectAll,
  (state) => state.filter((s) => s.status === EntityStatus.Online)
);

export const selectLatestSettingForEachKeyForSettingGroupUId = (uId: string) =>
  createSelector(selectAllSettings, (settings) => {
    const settingsForGroup = settings.filter((s) => s.parentUId === uId);
    const settingsByKeys: Setting[] = [];

    const now = new Date();

    const keys = new Set(settingsForGroup.map((setting) => setting.key));

    for (let key of keys) {
      const settingsWithSameKeys = settingsForGroup.filter(
        (setting) => setting.key === key
      );

      var latestSetting = settingsWithSameKeys.find(
        (setting) =>
          new Date(setting.validFrom || MIN_DATE.toJSON()) < now &&
          new Date(setting.validTo || MAX_DATE.toJSON()) > now
      );

      if (!latestSetting) {
        // get the last created setting
        latestSetting = settingsWithSameKeys.sort((a, b) =>
          new Date(a.created) > new Date(b.created) ? -1 : 1
        )[0];
      }

      if (latestSetting) {
        settingsByKeys.push(latestSetting);
      }
    }

    return settingsByKeys.sort((a, b) => a.key.localeCompare(b.key));
  });

export const selectLatestSettingForEachKeyForSettingGroupKey = (key: string) =>
  createSelector(
    (s) => s,
    selectAllSettingGroups,
    selectAllSettings,
    (state, settings) => {
      const group = settings.find((g) => g.key === key);
      if (!group) {
        console.warn(`No group found for key ${key}`);
        return [];
      }

      return selectLatestSettingForEachKeyForSettingGroupUId(group.uId)(state);
    }
  );

export const selectLatestSettingForKey = (groupUId: string, key: string) =>
  createSelector(
    selectLatestSettingForEachKeyForSettingGroupUId(groupUId),
    (settings) => settings.find((s) => s.key === key)
  );


export const selectCurrentSetting = (groupUIdOrKey: string, key: string) =>
  createSelector(selectAllSettings, selectAllSettingGroups, (settings, groups) => {

    const group = groups.find((g) => g.uId === groupUIdOrKey || g.key === groupUIdOrKey);

    settings = settings.filter((s) => s.parentUId === group.uId);

    const now = new Date();

    const currentSetting = settings.find(
      (setting) =>
        setting.key === key &&
        new Date(setting.validFrom || 0) < now &&
        new Date(setting.validTo || '2500-12-30T23:00:00Z') > now
    );

    return currentSetting;
  });

export const selectActiveSettings = (groupUIdOrKey: string, date: Date) =>
  createSelector(selectAllSettings, selectAllSettingGroups, (settings, groups) => {
    const group = groups.find((g) => g.uId === groupUIdOrKey || g.key === groupUIdOrKey);

    settings = settings.filter((s) => s.parentUId === group.uId);

    const activeSettings = settings.filter(
      (setting) =>
        new Date(setting.validFrom || 0) <= date &&
        (new Date(setting?.validTo ) > date || setting?.validTo === null)
    );

    return activeSettings;
  });

export const selectPendingSettings = (groupUId: string, key: string) =>
  createSelector(
    selectAllSettings,
    selectCurrentSetting(groupUId, key),
    (settings, current) => {
      settings = settings
        .filter((s) => s.parentUId === groupUId)
        .filter((s) => s.uId !== current?.uId);

      const endOfDay = moment().toDate();

      settings = settings.filter(
        (setting) =>
          setting.key === key && moment(setting.validFrom).isAfter(endOfDay)
      );

      return settings.sort((a, b) => a.validFrom.localeCompare(b.validFrom));
    }
  );

export const selectPreviousSettings = (groupUId: string, key: string) =>
  createSelector(
    selectAllSettings,
    selectCurrentSetting(groupUId, key),
    (settings, current) => {
      settings = settings
        .filter((s) => s.parentUId === groupUId)
        .filter((s) => s.uId !== current?.uId);
      const now = new Date();
      const previousSettings = settings.filter(
        (setting) =>
          setting.key === key &&
          new Date(setting.validFrom || 0) < now &&
          new Date(setting.validTo || '2500-12-30T23:00:00Z') < now
      );
      return previousSettings;
    }
  );

export const selectValueMetadataForGroup = (groupUId: string) =>
  createSelector(metadataAdapterSelectors.selectAll, (metadata) =>
    metadata.filter((m) => m.parentUId === groupUId)
  );

export const selectSettingGroup = (uIdOrKey: string) =>
  createSelector(selectAllSettingGroups, (groups) =>
    groups.find((g) => g.uId === uIdOrKey || g.key === uIdOrKey)
  );

export const selectSettingGroups = createSelector(
  selectAllSettingGroups,
  (groups) => groups
);

export const selectLoadingGroups = createSelector(
  keyValueSettingState,
  (s) => s.loadingGroups
);

export const selectLoadingSettings = createSelector(
  keyValueSettingState,
  (s) => s.loadingSettings
);

export const selectLoadingMetadata = createSelector(
  keyValueSettingState,
  (s) => s.loadingMetadata
);

export const selectLoadingSettingKey = (key: string) =>
  createSelector(
    keyValueSettingState,
    (s) => {
      return s.loadingSettingKeys?.[key] || s.loadingSettings || false;
    }
  );

export const selectSettingByUId = (uId: string) =>
  createSelector(selectAllSettings, (settings) =>
    settings.find((s) => s.uId === uId)
  );

export const selectSettingsWithGroupUIdAndKeyKey = (
  groupUId: string,
  key: string
) =>
  createSelector(selectAllSettings, (settings) =>
    settings.filter((s) => s.parentUId === groupUId && s.key === key)
  );

