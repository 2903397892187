import { createSelector } from '@ngrx/store';
import { selectAdministrationFeature } from '../administration.selectors';
import { accountTypeAdapter } from './account-type.state';
import { AccountTypeDto } from '@swagger/humanresources';
import moment from 'moment';

export const selectAccountTypeState = createSelector(
  selectAdministrationFeature,
  (s) => s.accountType
);

const { selectAll, selectEntities } = accountTypeAdapter.getSelectors(
  selectAccountTypeState
);

export const selectAccountTypes = createSelector(selectAll, (entities) =>
  entities.sort((a, b) => a.name.localeCompare(b.name))
);

export const selectLatestAccountTypeForEachKey = createSelector(
  selectAccountTypes,
  (accountTypes) => {
    const accountTypesByKeys: AccountTypeDto[] = [];

    const now = moment();

    const keys = new Set(accountTypes.map((accountType) => accountType.key));

    for (let key of keys) {
      const accountTypesWithSameKeys = accountTypes.filter(
        (accountType) => accountType.key === key
      );

      var latestAccountType = accountTypesWithSameKeys.find(
        (accountType) =>
          moment(accountType.start).isBefore(now) &&
          moment(accountType.stop).isAfter(now)
      );

      if(!latestAccountType) { 
        // get the last created account type
        latestAccountType = accountTypesWithSameKeys.sort((a, b) =>
          new Date(a.created) > new Date(b.created) ? -1 : 1
        )[0];
      }

      if (latestAccountType) {
        accountTypesByKeys.push(latestAccountType);
      }
    }

    return accountTypesByKeys.sort((a, b) => a.name.localeCompare(b.name));
  }
);

export const selectLoadingList = createSelector(
  selectAccountTypeState,
  (s) => s.loadingList
);

export const selectAccountTypeById = (id: string) =>
  createSelector(selectEntities, (entities) => entities[id]);

export const selectLoadingEntity = createSelector(
  selectAccountTypeState,
  (s) => s.loadingEntity
);

export const selectAccountTypeLoadingById = (id: string) =>
  createSelector(selectLoadingEntity, (loadingEntity) => loadingEntity[id]);

export const selectAccountTypesWithKey = (key: string) => {
  return createSelector(selectAccountTypes, (accountTypes) =>
    accountTypes.filter((accountType) => accountType.key === key)
  );
};

export const selectLatestAccountTypeForKey = (key: string) =>
  createSelector(selectAccountTypesWithKey(key), (accountTypes) => {
    console.log(accountTypes)

    return accountTypes.sort((a, b) =>
      new Date(a.created) > new Date(b.created) ? -1 : 1
    )[0]
  }
    
  );

  export const selectActiveAccountTypes = createSelector(
    selectAccountTypes,
    (accountTypes) =>
      accountTypes.filter(
        (accountType) =>
          moment(accountType.start).isBefore(moment()) &&
          moment(accountType.stop).isAfter(moment())
      )
  );

export const selectActiveAccountTypeForKey = (key: string) =>
  createSelector(selectAccountTypesWithKey(key), (accountTypes) =>
    accountTypes.find(
      (accountType) =>
        moment(accountType.start).isBefore(moment()) &&
        moment(accountType.stop).isAfter(moment())
    )
  );

export const selectPreviousAccountTypesForKey = (key: string) =>
  createSelector(selectAccountTypesWithKey(key), (accountTypes) =>
    accountTypes.filter((accountType) => 
      moment(accountType.stop).isBefore(moment())
    )
  );

export const selectUpcomingAccountTypesForKey = (key: string) =>
  createSelector(selectAccountTypesWithKey(key), (accountTypes) => {
    return accountTypes.filter((accountType) =>
      moment(accountType.start).isAfter(moment())
    );
  });
