import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { HierarchyDto } from '@swagger/humanresources';

export interface HierachyState extends EntityState<HierarchyDto> {
  loadingList: boolean;
  loadingEntity: Record<string, boolean>;
}

export const hierachyAdapter = createEntityAdapter<HierarchyDto>({
  selectId: (hierachy) => hierachy.uId,
  sortComparer: (a, b) => a.name.localeCompare(b.name),
});

export const hierachyInitialState: HierachyState =
  hierachyAdapter.getInitialState({
    loadingList: false,
    loadingEntity: {},
  });
