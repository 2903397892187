import {
  NgModule,
  APP_BOOTSTRAP_LISTENER,
  Type,
  InjectionToken,
  Inject,
} from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { FEATURE_NAME } from './administration.state';
import { administrationReducer } from './administration.reducer';
import { EffectSources, EffectsModule } from '@ngrx/effects';
import { AccountTypeEffects } from './account-type/account-type.effects';
import { KeyValueSettingEffects } from './key-value-setting/key-value-setting.effects';
import { HierarchyEffects } from './hierachy/hierarchy.effects';

/**
 * Effects wurden erstellet bevor APP_INITIALIZER fertig war.
 * Dies ist ein Workaround, um die Effekte zu bootstrappen.
 * Siehe
 * https://github.com/ngrx/platform/issues/931
 * https://github.com/brandonroberts/effects-issue-example
 */
const BOOTSTRAP_EFFECTS = new InjectionToken('Bootstrap Effects');

function bootstrapEffects(effects: Type<any>[], sources: EffectSources) {
  return () => {
    effects.forEach((effect) => sources.addEffects(effect));
  };
}

function createInstances(...instances: any[]) {
  return instances;
}

function provideBootstrapEffects(effects: Type<any>[]) {
  return [
    effects,
    { provide: BOOTSTRAP_EFFECTS, deps: effects, useFactory: createInstances },
    {
      provide: APP_BOOTSTRAP_LISTENER,
      multi: true,
      useFactory: bootstrapEffects,
      deps: [[new Inject(BOOTSTRAP_EFFECTS)], EffectSources],
    },
  ];
}

@NgModule({
  imports: [
    StoreModule.forFeature(FEATURE_NAME, administrationReducer),
    EffectsModule.forFeature([]),
  ],
  providers: provideBootstrapEffects([
    AccountTypeEffects,
    KeyValueSettingEffects,
    HierarchyEffects,
  ]),
})
export class AdministrationStoreModule {}
