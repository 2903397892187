import { inject, Injectable } from '@angular/core';
import { HierarchyDto, HierarchyService } from '@swagger/humanresources';
import { map } from 'rxjs';
import { HierachyValidators } from './hierachy.validators';

@Injectable({ providedIn: 'root' })
export class HierarchyRespositoryService {
  private hierachyService = inject(HierarchyService);

  updateHierachy(uId: string, body: HierarchyDto) {
    try {
      return this.hierachyService
        .hierarchyUpdateHierarchy({
          hierarchyUId: uId,
          body: body,
        })
        .pipe(
          map((response) => {
            return response.result;
          })
        );
    } catch (e) {
      console.error(e);
      throw new Error(e);
    }
  }

  createHierachy(hierachy: HierarchyDto) {
    try {
      HierachyValidators.validateHierachyDto({ hierachy });

      return this.hierachyService
        .hierarchyCreateHierarchy({
          body: hierachy,
        })
        .pipe(map((response) => response.result));
    } catch (e) {
      console.error(e);
      throw new Error(e);
    }
  }

  moveChildren(sourceUid: string, targetUid: string) {
    try {
      return this.hierachyService
        .hierarchyMoveChildren({
          body: {
            sourceParentUId: sourceUid,
            targetParentUId: targetUid,
          },
        })
        .pipe(map((hierachies) => hierachies));
    } catch (e) {
      console.error(e);
      throw new Error(e);
    }
  }
}
