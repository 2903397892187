import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, OnInitEffects, ofType } from '@ngrx/effects';
import { AccountTypeService } from '@swagger/humanresources';
import { fetchAccountType, fetchAccountTypes } from './account-type.actions';
import {
  catchError,
  debounceTime,
  map,
  switchMap,
} from 'rxjs';

@Injectable()
export class AccountTypeEffects implements OnInitEffects {
  private actions$ = inject(Actions);
  private api = inject(AccountTypeService);

  fetchAccountTypes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchAccountTypes.request),
      debounceTime(1000),
      switchMap(() =>
        this.api.accountTypeQuery({ body: {}, eagerLoading: 1 }).pipe(
          map((response) =>
            fetchAccountTypes.success({ accountTypes: response.result })
          ),
          catchError((error) => [fetchAccountTypes.failure({ error })])
        )
      )
    )
  );

  fetchAccountType$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchAccountType.request),
      switchMap(({ uId }) =>
        this.api.accountTypeGet({ accountTypeUId: uId, eagerLoading: 1 }).pipe(
          map((response) =>
            fetchAccountType.success({
              uId,
              accountType: response.result,
            })
          ),
          catchError((error) => [fetchAccountType.failure({ uId, error })])
        )
      )
    )
  );

  ngrxOnInitEffects() {
    return fetchAccountTypes.request();
  }
}
