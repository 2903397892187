import { EntityState, createEntityAdapter } from '@ngrx/entity';
import { AccountTypeDto } from '@swagger/humanresources';

export interface AccountTypeState extends EntityState<AccountTypeDto> {
  loadingList: boolean;
  loadingEntity: Record<string, boolean>;
}

export const accountTypeAdapter = createEntityAdapter<AccountTypeDto>({
    selectId: (accountType) => accountType.uId,
    sortComparer: (a, b) => a.name.localeCompare(b.name),
});

export const accountTypeInitialState: AccountTypeState =
  accountTypeAdapter.getInitialState({
    loadingList: false,
    loadingEntity: {},
  });


  