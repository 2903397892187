import { Injectable } from '@angular/core';
import {
  AssignedStyleDto,
  AvailableScheduleItemTypeDto,
  QueryTokenDto,
  ScheduleItemTypeDto,
  ScheduleTypeDto,
} from '@swagger/humanresources';
import { ScheduleTypeService } from '@swagger/humanresources';
import { firstValueFrom, map, tap } from 'rxjs';
import { ScheduleTypeValidators } from './schedule-type.validators';
import { DynamicFormMetadataInput } from '@shared/dynamic-form';
import { UntypedFormGroup } from '@angular/forms';
import moment from 'moment';
import { ScheduleTypeEntity } from '../pages/schedule-type-setting-page/schedule-type-settings-page.store';

@Injectable({ providedIn: 'root' })
export class HrpScheduleTypeService {
  getScheduleTypes(scheduleTypeUId?: string) {
    return this.scheduleType
      .scheduleTypeListScheduleTypes({
        parentUId: scheduleTypeUId,
      })
      .pipe(
        map((response) => {
          return response.result;
        })
      );
  }

  getAllScheduleTypes({ eagerLoading = 1 }) {
    return this.scheduleType
      .scheduleTypeQueryScheduleType({ body: {}, eagerLoading })
      .pipe(map((response) => response.result));
  }

  getScheduleTypeDetails({
    scheduleTypeUId,
    eagerLoading = 2,
  }: {
    scheduleTypeUId: string;
    eagerLoading?: number;
  }) {
    return this.scheduleType
      .scheduleTypeGetScheduleType({
        scheduleTypeUId,
        eagerLoading,
      })
      .pipe(map((response) => response.result));
  }

  createScheduleType(scheduleType: ScheduleTypeDto) {
    try {
      ScheduleTypeValidators.validateScheduleTypeDto({
        scheduleType,
      });

      return this.scheduleType
        .scheduleTypeCreateScheduleType({
          body: scheduleType,
          eagerLoading: 2,
        })
        .pipe(map((response) => response.result));
    } catch (e) {
      console.error(e);
      throw new Error(e);
    }
  }

  updateScheduleType(scheduleType: ScheduleTypeDto) {
    try {
      ScheduleTypeValidators.validateScheduleTypeDto({
        scheduleType,
        update: true,
      });

      return this.scheduleType
        .scheduleTypeUpdateScheduleType({
          scheduleTypeUId: scheduleType.uId,
          body: scheduleType,
          eagerLoading: 2,
        })
        .pipe(map((response) => response.result));
    } catch (e) {
      console.error(e);
      throw new Error(e);
    }
  }

  async callCreateScheduleType(scheduleType: ScheduleTypeDto) {
    return await firstValueFrom(
      this.createScheduleType(scheduleType).pipe(
        map((result) => {
          return { ...result, uId: result.uId };
        })
      )
    );
  }

  async callUpdateScheduleType(scheduleType: ScheduleTypeDto) {
    return await firstValueFrom(
      this.updateScheduleType(scheduleType).pipe(
        map((result) => {
          return { ...result, uId: result.uId };
        })
      )
    );
  }

  _getSelectedItemsFromDynamicFormDialog({
    scheduleItemTypes,
    form,
  }: {
    scheduleItemTypes: ScheduleItemTypeDto[];
    form: UntypedFormGroup;
  }) {
    const selectedKeys = Object.keys(form.value).filter(
      (key) => form.value[key] === true
    );
    return scheduleItemTypes.filter((scheduleItemType) =>
      selectedKeys.includes(scheduleItemType.key)
    );
  }

  // Default Data Set for AvailableScheduleItemTypeDto with required attributes
  _getDefaultAvailableScheduleItemTypeDtoData(uId: string) {
    return {
      data: {
        availableOnPublicHoliday: false,
        availableAtDayOfWeek: 0,
        scheduleItemType: {
          uId,
        },
        start: moment().toISOString(),
        stop: moment().add(1, 'year').toISOString(),
      },
    };
  }

  _createDynamicFormDialogMetaData(
    scheduleItemTypes: ScheduleItemTypeDto[]
  ): DynamicFormMetadataInput {
    const metadata: DynamicFormMetadataInput = [];

    for (const scheduleItemType of scheduleItemTypes) {
      metadata.push({
        controlType: 'checkbox',
        key: scheduleItemType?.key,
        name: scheduleItemType?.name,
      });
    }

    return metadata;
  }

getAvailableScheduleItemType(scheduleTypeUId: string, availableScheduleItemTypeUId: string) {
    return this.scheduleType
      .scheduleTypeGetAvailableScheduleItemType({
        scheduleTypeUId,
        availableScheduleItemTypeUId,
        eagerLoading: 4,
      })
      .pipe(map((response) => response.result));
  }

  createAvailableScheduleItemType({
    scheduleTypeUId,
    item,
  }: {
    scheduleTypeUId: string;
    item: AvailableScheduleItemTypeDto;
  }) {
    try {
      return this.scheduleType
        .scheduleTypeCreateAvailableScheduleItemType({
          scheduleTypeUId,
          body: item,
        })
        .pipe(map((response) => response.result));
    } catch (e) {
      console.error(e);
      throw new Error(e);
    }
  }

  updateAvailableScheduleItemType(
    scheduleTypeUId: string,
    item: AvailableScheduleItemTypeDto,
    eagerLoading = 4
  ) {
    try {
      return this.scheduleType
        .scheduleTypeUpdateAvailableScheduleItemType({
          scheduleTypeUId,
          availableScheduleItemTypeUId: item.uId,
          body: item,
          eagerLoading,
        })
        .pipe(map((response) => response.result));
    } catch (e) {
      console.error(e);
      throw new Error(e);
    }
  }

  deleteAvailableScheduleItemType({
    scheduleTypeUId,
    availableScheduleItemTypeUId,
    deletionComment
  }: {
    scheduleTypeUId: string;
    availableScheduleItemTypeUId: string;
    deletionComment: string;
  }) {
    try {
      return this.scheduleType
        .scheduleTypeDeleteAvailableScheduleItemType({
          scheduleTypeUId,
          availableScheduleItemTypeUId,
          deletionComment: deletionComment
        })
        .pipe(map((response) => response.result));
    } catch (e) {
      console.error(e);
      throw new Error(e);
    }
  }

  getAssignedStyleTypes(scheduleTypeUId: string) {
    return this.scheduleType
      .scheduleTypeGetAssignedStyleTypes({
        scheduleTypeUId,
      })
      .pipe(map((response) => response.result));
  }

  createAssignedStyle({
    scheduleTypeUId,
    availableScheduleItemTypeUId,
    body,
    eagerLoading = 2,
  }: {
    scheduleTypeUId: string;
    availableScheduleItemTypeUId: string;
    body: AssignedStyleDto;
    eagerLoading?: number;
  }) {
    return this.scheduleType
      .scheduleTypeCreateAssignedStyle({
        scheduleTypeUId,
        availableScheduleItemTypeUId,
        body,
        eagerLoading,
      })
      .pipe(map((response) => response.result));
  }

  updateAssignedStyle({
    scheduleTypeUId,
    availableScheduleItemTypeUId,
    assignedStyleUId,
    body,
  }: {
    scheduleTypeUId: string;
    availableScheduleItemTypeUId: string;
    assignedStyleUId: string;
    body: AssignedStyleDto;
  }) {
    return this.scheduleType
      .scheduleTypeUpdateAssignedStyle({
        scheduleTypeUId,
        availableScheduleItemTypeUId,
        assignedStyleUId,
        body,
      })
      .pipe(map((response) => response.result));
  }

  constructor(private scheduleType: ScheduleTypeService) {}
}
