import { ScheduleTypeDto } from '@swagger/humanresources';

export class ValidationError extends Error {
  constructor(
    public readonly property: string,
    public readonly value: any,
    message: string
  ) {
    super(message);
  }
}

export class ScheduleTypeValidators {
  private constructor() {}

  static validateScheduleTypeDto({
    scheduleType,
    update,
  }: {
    scheduleType: ScheduleTypeDto;
    update?: boolean;
  }): void {
    if (!!update && !scheduleType.uId) {
      throw new ValidationError(
        'scheduleTypeDtoUid',
        scheduleType?.uId,
        'Schedule  Type uId is required'
      );
    }

    if (!scheduleType?.key) {
      throw new ValidationError('key', scheduleType?.key, 'Key is required');
    }

    if (!scheduleType?.name) {
      throw new ValidationError('name', scheduleType?.name, 'Name is required');
    }

    return null;
  }
}
