import { WeekDay } from '@angular/common';
import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { DateAdapter } from '@paragondata/ngx-ui/core';

type Precision = 'year' | 'month' | 'day' | 'time';
type Mode = 'day' | 'week' | 'month' | 'year' | 'planning-week';

@Injectable({ providedIn: 'root' })
export class DateService {
  constructor(
    @Inject(LOCALE_ID) public locale: string,
    private dateAdapter: DateAdapter
  ) {}

  getDatesForMonth(month: number, year?: number) {
    const date = new Date(year || this.today().getFullYear(), month, 1);

    const monthDates = [];
    while (date.getMonth() === month) {
      monthDates.push(new Date(date));
      date.setDate(date.getDate() + 1);
    }

    return monthDates;
  }

  getMonths() {
    return [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'Oktober',
      'November',
      'Dezember',
    ];
  }


  isToday(date: Date): boolean {
    const today = new Date();
    return (
      date.getDate() === today.getDate() &&
      date.getFullYear() === today.getFullYear() &&
      date.getMonth() === today.getMonth()
    );
  }

  isSameDay(date1: Date, date2: Date): boolean {
    return (
      date1.getDate() === date2.getDate() &&
      date1.getFullYear() === date2.getFullYear() &&
      date1.getMonth() === date2.getMonth()
    );
  }

  getWeekNumber(date: Date) {
    // Copy date so don't modify original
    date = new Date(
      Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())
    );
    // Set to nearest Thursday: current date + 4 - current day number
    // Make Sunday's day number 7
    date.setUTCDate(date.getUTCDate() + 4 - (date.getUTCDay() || 7));
    // Get first day of year
    var yearStart = new Date(Date.UTC(date.getUTCFullYear(), 0, 1));
    // Calculate full weeks to nearest Thursday
    var weekNo = Math.ceil(
      ((date.getTime() - yearStart.getTime()) / 86400000 + 1) / 7
    );
    // Return array of year and week number
    return [date.getUTCFullYear(), weekNo];
  }

  getDateId(date: Date) {
    return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
  }

  getYear(date: Date): number {
    return date.getFullYear();
  }
  getMonth(date: Date): number {
    return date.getMonth();
  }
  getDate(date: Date): number {
    return date.getDate();
  }
  getYearName(date: Date): string {
    return this.getYear(date).toString();
  }

  getMonthName(date: Date): string;
  getMonthName(month: number): string;
  getMonthName(dateOrMonth: Date | number): string {
    let date = new Date();
    if (dateOrMonth instanceof Date) {
      date = dateOrMonth;
    } else {
      date.setMonth(dateOrMonth);
    }
    return date.toLocaleString(this.locale, { month: 'long' });
  }

  getDateName(date: Date): string {
    return this.getDate(date).toString();
  }
  addCalendarMonths(date: Date, months: number): Date {
    return this.createDate(
      this.getYear(date),
      this.getMonth(date) + months,
      this.getDate(date)
    );
  }
  addCalendarDays(date: Date, days: number): Date {
    return this.createDate(
      this.getYear(date),
      this.getMonth(date),
      this.getDate(date) + days
    );
  }

  getFirstDateOfWeek(date: Date): Date {
    const clone = new Date(date);
    const day = clone.getDay();
    const diff = clone.getDate() - day + (day === 0 ? -6 : 1); // Start of Week = Monday
    return this.getStartOfTheDay(new Date(clone.setDate(diff)));
  }

  getLastDateOfWeek(date: Date): Date {
    const clone = new Date(date);
    const day = clone.getDay();
    const diff = clone.getDate() - day + (day === 0 ? 0 : 7); // End of Week = Sunday
    return this.getEndOfTheDay(new Date(clone.setDate(diff)));
  }

  getFirstDateOfMonth(date: Date) {
    return this.createDate(this.getYear(date), this.getMonth(date), 1);
  }

  getLastDateOfMonth(date: Date) {
   //get first day of next month at 00:00:00
    return this.createDate(this.getYear(date), this.getMonth(date) + 1, 1);
  }

  getDifferenceInDays(first: Date, second: Date) {
    return (first.getTime() - second.getTime()) / (1000 * 3600 * 24);
  }

  getDayOfWeek(date: Date) {
    return date.getDay();
  }

  getStartOfTheDay(date: Date) {
    return this.createDate(
      date?.getFullYear(),
      date?.getMonth(),
      date?.getDate()
    );
  }

  getEndOfTheDay(date: Date) {
    const nextDate = this.createDate(
      date?.getFullYear(),
      date?.getMonth(),
      date?.getDate() + 1
    );
    return new Date(nextDate.getTime() - 1);
  }

  createDate(year: number, month: number, date: number): Date {
    const result = new Date(year, month, date);
    // abbreviations for 19xx.
    if (year >= 0 && year < 100) {
      result.setFullYear(this.getYear(result) - 1900);
    }
    return result;
  }

  parseDate(date: string) {
    return new Date(date);
  }

  today(): Date {
    return new Date(new Date().toJSON().slice(0, 10).replace(/-/g, '/'));
  }

  isValid(date: any): date is Date {
    return date instanceof Date && !isNaN(date.getDate());
  }

  // #2314 Wird nun für die Kalenderanzeige "Mein Plan -> Monat" und für den Monatsfooter dort (Anzeige der KWs) verwendet
  getDatesAndCalendarWeeksForMonth(
    date: Date
  ): { week: number; dates: Date[] }[] {
    const result: { week: number; dates: Date[] }[] = [];
    const weeks = this.getCalendarWeeksForMonth(date);

    for (const week of weeks) {
      let iWeek = week;
      if (this.getMonth(date) === 11 && week === 1) {
        iWeek = 53;
      }

      const dates = this.getDatesForCalendarWeek(this.getYear(date), iWeek);

      result.push({
        week,
        dates,
      });
    }

    return result;
  }

  getDatesAndCalendarWeeksForMonths(
    dates: Date[]
  ): { week: number; dates: Date[] }[] {
    const result: { week: number; dates: Date[] }[] = [];

    dates.forEach((date) => {
      const weeks = this.getCalendarWeeksForMonth(date);
      for (const week of weeks) {
        let iWeek = week;
        if (date.getMonth() === 11 && week === 1) {
          iWeek = 53;
        }

        const dates = this.getDatesForCalendarWeek(this.getYear(date), iWeek);

        result.push({
          week,
          dates,
        });
      }
    });

    return result;
  }

  getDatesForCalendarWeek(year: number, week: number): Date[] {
    const firstDateOfYear = this.createDate(year, 0, 1);
    const firstDayOfYear = firstDateOfYear.getDay();
    let firstDayOfWeek = 0;

    if (firstDayOfYear === 0) {
      firstDayOfWeek = 2;
    } else if (firstDayOfYear <= 3) {
      firstDayOfWeek = -firstDayOfYear + 2;
    } else {
      firstDayOfWeek = -firstDayOfYear + 9;
    }

    const firstDateOfWeek = this.createDate(year, 0, firstDayOfWeek);

    const dates: Date[] = [];

    for (let i = 0; i < 7; i++) {
      dates.push(
        this.createDate(
          this.getYear(firstDateOfWeek),
          this.getMonth(firstDateOfWeek),
          this.getDate(firstDateOfWeek) + i + (week - 1) * 7
        )
      );
    }
    return dates;
  }

  getCalendarWeek(date: Date): number {
    const firstDateOfYear = this.createDate(this.getYear(date), 0, 1);

    const totalDays = (date.getTime() - firstDateOfYear.getTime()) / 86400000;

    const week = Math.ceil((totalDays + 4 - (date.getDay() || 7)) / 7);

    return week > 52 ? 1 : week;
  }

  getCalendarWeeksForMonth(date: Date): number[] {
    let firstDateOfMonth = this.createDate(
      this.getYear(date),
      this.getMonth(date),
      1
    );
    const weekStart = this.getCalendarWeek(firstDateOfMonth);
    let weekEnd = this.getCalendarWeek(
      this.createDate(
        this.getYear(firstDateOfMonth),
        this.getMonth(firstDateOfMonth) + 1,
        0
      )
    );
    let weeks: number[] = [];

    if (weekEnd === 1) {
      weekEnd = 53;
    }

    for (let index = 0; index <= weekEnd - weekStart; index++) {
      weeks.push(weekStart + index);
    }

    return weeks;
  }

  getMonthForCalendarWeek(year: number, week: number): number {
    const dates = this.getDatesForCalendarWeek(year, week);
    return this.getMonth(dates[0]);
  }

  getNextMonth(year: number, month: number): Date {
    if (month === 11) {
      return this.createDate(year + 1, 1, 1);
    } else {
      return this.createDate(year, month - 1, 1);
    }
  }

  getPreviousMonth(year: number, month: number): Date {
    if (month === 0) {
      return this.createDate(year - 1, 11, 1);
    } else {
      return this.createDate(year, month - 1, 1);
    }
  }

  compareDate(first: Date, second: Date) {
    if (!first || !second) {
      return null;
    }

    return (
      this.getYear(first) - this.getYear(second) ||
      this.getMonth(first) - this.getMonth(second) ||
      this.getDate(first) - this.getDate(second)
    );
  }

  compareYearAndMonth(first: Date, second: Date) {
    if (!first || !second) {
      return null;
    }
    return (
      this.getYear(first) - this.getYear(second) ||
      this.getMonth(first) - this.getMonth(second)
    );
  }

  isLessThan({
    first,
    second,
    precision,
  }: {
    first: Date;
    second: Date;
    precision?: Precision;
  }): boolean {
    return (
      this.formatDate(first, precision) < this.formatDate(second, precision)
    );
  }

  isLessEqual({
    first,
    second,
    precision,
  }: {
    first: Date;
    second: Date;
    precision?: Precision;
  }): boolean {
    return (
      this.formatDate(first, precision) <= this.formatDate(second, precision)
    );
  }

  isGreaterThan({
    first,
    second,
    precision,
  }: {
    first: Date;
    second: Date;
    precision?: Precision;
  }): boolean {
    return (
      this.formatDate(first, precision) > this.formatDate(second, precision)
    );
  }

  isGreaterEqual({
    first,
    second,
    precision,
  }: {
    first: Date;
    second: Date;
    precision?: Precision;
  }): boolean {
    return (
      this.formatDate(first, precision) >= this.formatDate(second, precision)
    );
  }

  equals({
    first,
    second,
    precision,
  }: {
    first: Date;
    second: Date;
    precision?: Precision;
  }): boolean {
    return (
      this.formatDate(first, precision).getTime() ===
      this.formatDate(second, precision).getTime()
    );
  }

  private formatDate(date: Date, precision: Precision = 'time'): Date {
    switch (precision) {
      case 'year':
        return new Date(this.getYear(date));
      case 'month':
        return new Date(this.getYear(date), this.getMonth(date));
      case 'day':
        return new Date(
          this.getYear(date),
          this.getMonth(date),
          this.getDate(date)
        );
      case 'time':
        return date;
      default:
        return date;
    }
  }

  public getEndOfDay(date: Date): Date {
    //get next day 00:00:00
    const nextDay = new Date(date);
    nextDay.setDate(date.getDate() + 1);
    nextDay.setHours(0, 0, 0, 0);
    return new Date(nextDay);
  }

  public getEndOfNextDay(date: Date): Date {
    //get next day 00:00:00
    const nextDay = new Date(date);
    nextDay.setDate(date.getDate() + 2);
    nextDay.setHours(0, 0, 0, 0);
    return new Date(nextDay);
  }

  public getStartDayWeek(date: Date): Date {
    const dayOfWeek = date.getDay();
    const daysToSubtract = (dayOfWeek + 6) % 7;
    const startDayOfPreviousWeek = new Date(date);
    startDayOfPreviousWeek.setDate(date.getDate() - daysToSubtract - 1);
    return startDayOfPreviousWeek;
  }

  public getStartDayWeekUI(date: Date): Date {
    const dayOfWeek = date.getDay();
    const daysToSubtract = (dayOfWeek + 6) % 7;
    const startDayOfPreviousWeek = new Date(date);
    startDayOfPreviousWeek.setDate(date.getDate() - daysToSubtract);
    return startDayOfPreviousWeek;
  }

  public getLastDayWeek(date: Date): Date {
    const dayOfWeek = date.getDay();
    const daysToAdd = (7 - dayOfWeek) % 7;
    const lastDayOfNextWeek = new Date(date);
    lastDayOfNextWeek.setDate(date.getDate() + daysToAdd);
    return lastDayOfNextWeek;
  }

  public getStartDayMonth(date: Date): Date {
    const month = date.getMonth();
    const year = date.getFullYear();
    // Create a new date for the first day of the current month
    const firstDayOfMonth = new Date(year, month, 1);
    return firstDayOfMonth;
  }

  //für die Monatsansicht müssen komplette Wochen geladen werden, nicht nur exakt der Monat
  public getVisibleStartDayMonth(date: Date): Date {
    const month = date.getMonth();
    const year = date.getFullYear();

    const weekWithFirstDayOfMonth = this.getWeekNumber(
      new Date(year, month, 1)
    );
    const startDayOfVisibleMonth = this.getDatesForCalendarWeek(
      year,
      weekWithFirstDayOfMonth[1]
    )[0];
    return startDayOfVisibleMonth;
  }

  public getVisibleEndDayMonth(date: Date): Date {
    const month = date.getMonth();
    const year = date.getFullYear();

    const lastDayOfMonth = this.getLastDateOfMonth(date);
    const weekWithLastDayOfMonth = this.getWeekNumber(lastDayOfMonth);
    const endDayOfVisibleMonth = this.getDatesForCalendarWeek(
      year,
      weekWithLastDayOfMonth[1]
    )[6];
    return endDayOfVisibleMonth;
  }

  public getLastDayNextMonth(date: Date): Date {
    const month = date.getMonth() + 1;
    const year = date.getFullYear();

    // Create a new date for the first day of the next month
    const lastDayOfNextMonth = new Date(year, month + 1, 0);
    // Set the time to 23:59:59
    lastDayOfNextMonth.setHours(23, 59, 59, 999);
    return lastDayOfNextMonth;
  }

  public getDateRangeForWeek(selectedDate: Date): [Date, Date] {
    const firstDateOfWeek = this.dateAdapter.getFirstDateOfWeek(
      selectedDate,
      WeekDay.Monday
    );
    const lastDateOfWeek = this.dateAdapter.addCalendarDays(firstDateOfWeek, 6);

    return [firstDateOfWeek, lastDateOfWeek];
  }

  public getDateRangeForMonth(selectedDate: Date): [Date, Date] {
    const firstDateOfMonth = this.dateAdapter.getFirstDateOfMonth(selectedDate);
    const lastDateOfMonth = this.dateAdapter.getLastDateOfMonth(selectedDate);

    return [firstDateOfMonth, lastDateOfMonth];
  }

  public getFirstDayNextYear(date: Date): Date {
    // Create a new date for the first day of the next year at 00:00:00
    const year = date.getFullYear() + 1;
    const firstDayOfYear = new Date(year, 0, 1, 0, 0, 0, 0);
    return firstDayOfYear;
  }

  public removeTimeFromDate(date: any) {
    const newdate = new Date(date);
    return newdate.toDateString();
  }

  public getTimeString(value: Date): string {
    let hours: number = value.getHours();
    let minutes: number = value.getMinutes();

    // Format hours and minutes as strings with leading zeros if necessary
    let formattedHours: string = hours < 10 ? '0' + hours : hours.toString();
    let formattedMinutes: string =
      minutes < 10 ? '0' + minutes : minutes.toString();

    let timeString: string = formattedHours + ':' + formattedMinutes;

    return timeString;
  }

  // Function to combine date and time into a new Date object
  combineDateAndTime(date: Date, time: string): Date {
    const [hours, minutes] = time?.split(':').map(Number);
    const combinedDate = new Date(date);
    combinedDate.setHours(hours);
    combinedDate.setMinutes(minutes);
    return combinedDate;
  }

  //für die Monatsansicht im Kalender müssen komplette Wochen geladen werden, nicht nur exakt der Monat
  getExtendedMonth({ selectedDate }: { selectedDate: Date }): {
    start: Date;
    stop: Date;
  } {
    const startMonth = this.getVisibleStartDayMonth(selectedDate);
    const stopMonth = this.getVisibleEndDayMonth(selectedDate);
    const start = this.getStartOfTheDay(startMonth);
    const stop = this.getEndOfDay(stopMonth);

    // Bei Jahreswechsel prüfen, dass der Startzeitpunkt nicht nach dem Stopzeitpunkt liegt
    if (start.getMonth() > stop.getMonth() && start.getFullYear() === stop.getFullYear()) {
      stop.setFullYear(stop.getFullYear() + 1);
    }

    return { start, stop };
  }

  getTimespan({
    selectedDate,
    mode,
  }: {
    selectedDate: Date;
    mode: Mode;
  }): {
    start: Date;
    stop: Date;
  } {
    // Für die query$ Aufrufe in "Mein Plan" und "Plan" sowie für den WorkingSummaryPerDay Request in "Mein Plan" muss folgendes beachtet werden:
    // Start Zeitpunkt ist immer Inklusive
    // Stop Zeitpunkt ist immer Exklusive
    // Bsp:
    // Wenn man den Wochenzeitraum Mo 20.05.2024 - So 26.05.2024 abfragen möchte, müssen die start & stop Werte folgendermaßen aussehen:
    // start = 20.05.2024 00:00:00 GMT+0200 (2024-05-19T22:00:00.000Z - Payload fürs Backend)
    // stop = 27.05.2024 00:00:00 GMT+0200 (2024-05-26T22:00:00.000Z - Payload fürs Backend)

    const startDay = this.getStartOfTheDay(selectedDate);
    const stopDay = this.getEndOfDay(selectedDate);
    let start = startDay;
    let stop = stopDay;

    switch (mode) {
      case 'week':
        const startWeek = this.getStartDayWeek(selectedDate);
        const stopWeek = this.getLastDayWeek(selectedDate);
        start = this.getEndOfDay(startWeek);
        stop = this.getEndOfDay(stopWeek);
        break;
      case 'month':
        const startMonth = this.getStartDayMonth(selectedDate)
        const stopMonth = this.getLastDateOfMonth(selectedDate)
        start = this.getStartOfTheDay(startMonth);
        stop = this.getEndOfDay(stopMonth);
        break;
      case 'year':
        const startYear = new Date(selectedDate.getFullYear(), 0, 1, 0, 0, 0);
        const stopYear = this.getFirstDayNextYear(selectedDate);
        start = startYear;
        stop = stopYear;
        break;
    }

    return { start, stop };
  }

  getStartDateTimeString(startDay: Date, startTime: string): string {
    const time = startTime || '00:00';
    const startDateTime: Date = this.combineDateAndTime(
      startDay,
      time
    );
    return startDateTime.toISOString();
  }

 getEndDateTimeString(endDay: Date, endTime: string): string {
    const time = endTime || '24:00';
    const endDateTime: Date = this.combineDateAndTime(endDay, time);
    return endDateTime.toISOString();
  }


}
