import { createReducer, on } from '@ngrx/store';
import {
  keyValueSettingInitialState,
  groupsAdapter,
  metadataAdapter,
  settingsAdapter,
} from './key-value-setting.state';
import {
  fetchSetting,
  fetchSettingByGroupUIdAndKey,
  fetchSettingGroup,
  fetchSettingGroups,
  fetchSettings,
  fetchSingleValueMetadata,
  fetchValueMetadata,
  removeSettingByUId,
} from './key-value-setting.actions';
import { MAX_DATE, MIN_DATE } from '@shared/constants';

export const keyValueSettingReducer = createReducer(
  keyValueSettingInitialState,
  on(fetchSettingGroups.request, (state) => ({
    ...state,
    loadingGroups: true,
  })),
  on(fetchSettingGroups.success, (state, { settingGroups }) => {
    const nextState = structuredClone(state);
    nextState.groups = groupsAdapter.removeAll(nextState.groups);
    nextState.groups = groupsAdapter.addMany(settingGroups, nextState.groups);
    nextState.loadingGroups = false;
    return nextState;
  }),
  on(fetchSettingGroups.failure, (state, { error }) => ({
    ...state,
    loadingGroups: false,
  })),
  on(fetchSettingGroup.request, (state, { uId }) => ({
    ...state,
  })),
  on(fetchSettingGroup.success, (state, { settingGroup }) => {
    let nextState = structuredClone(state);
    nextState.groups = groupsAdapter.upsertOne(settingGroup, nextState.groups);
    return nextState;
  }),
  on(fetchSettingGroup.failure, (state, { error }) => ({
    ...state,
  })),
  on(fetchSettings.request, (state) => ({
    ...state,
    loadingSettings: true,
  })),
  on(fetchSettings.success, (state, { settings }) => {
    let nextState = structuredClone(state);
    nextState.settings = settingsAdapter.removeAll(nextState.settings);
    nextState.settings = settingsAdapter.addMany(
      settings.map((s) => ({
        ...s,
        validFrom: s.validFrom ?? MIN_DATE.toJSON(),
        validTo: s.validTo ?? MAX_DATE.toJSON(),
      })),
      nextState.settings
    );
    return { ...nextState, loadingSettings: false };
  }),
  on(fetchSettings.failure, (state, { error }) => ({
    ...state,
    loadingSettings: false,
  })),
  on(fetchSetting.request, (state, { uId }) => ({
    ...state,
  })),
  on(fetchSetting.success, (state, { setting }) => {
    let nextState = structuredClone(state);
    nextState.settings = settingsAdapter.upsertOne(
      {
        ...setting,
        validFrom: setting.validFrom ?? MIN_DATE.toJSON(),
        validTo: setting.validTo ?? MAX_DATE.toJSON(),
      },
      nextState.settings
    );
    return nextState;
  }),
  on(fetchSetting.failure, (state, { error }) => ({
    ...state,
  })),
  on(fetchValueMetadata.request, (state) => ({
    ...state,
    loadingMetadata: true,
  })),
  on(fetchValueMetadata.success, (state, { metadata }) => {
    let nextState = structuredClone(state);
    nextState.metadata = metadataAdapter.removeAll(nextState.metadata);
    nextState.metadata = metadataAdapter.addMany(metadata, nextState.metadata);
    nextState.loadingMetadata = false;
    return nextState;
  }),
  on(fetchValueMetadata.failure, (state, { error }) => ({
    ...state,
    loadingMetadata: false,
  })),
  on(fetchSingleValueMetadata.request, (state, { uId }) => ({
    ...state,
  })),
  on(fetchSingleValueMetadata.success, (state, { settingMetadata }) => {
    let nextState = structuredClone(state);
    nextState.metadata = metadataAdapter.upsertOne(
      settingMetadata,
      nextState.metadata
    );
    nextState.loadingMetadata = false;
    return nextState;
  }),
  on(fetchSingleValueMetadata.failure, (state, { error }) => ({
    ...state,
    loadingMetadata: false,
  })),
  on(fetchSettingByGroupUIdAndKey.request, (state, { key }) => ({
    ...state,
    loadingSettingKeys: {
      ...(state.loadingSettingKeys ?? {}),
      [key]: true,
    },
  })),
  on(fetchSettingByGroupUIdAndKey.success, (state, { key, settings }) => {
    let nextState = structuredClone(state);
    nextState.settings = settingsAdapter.upsertMany(settings.map(setting => ({
      ...setting,
      validFrom: setting.validFrom ?? MIN_DATE.toJSON(),
      validTo: setting.validTo ?? MAX_DATE.toJSON(),
    }))      ,
      nextState.settings
    );
    return {
      ...nextState,
      loadingSettingKeys: {
        ...(nextState.loadingSettingKeys ?? {}),
        [key]: false,
      },
    };
  }),
  on(fetchSettingByGroupUIdAndKey.failure, (state, { key, error }) => ({
    ...state,
    loadingSettingKeys: {
      ...state.loadingSettingKeys,
      ...(state.loadingSettingKeys ?? {}),
      [key]: false,
    },
  })),
  on(removeSettingByUId, (state, { uId }) => { 
      let nextState = structuredClone(state);

      nextState.settings = settingsAdapter.removeOne(uId, nextState.settings);

      return nextState;
  })
);
