import { Injectable } from '@angular/core';
import { HierarchyDto } from '@swagger/humanresources';

@Injectable({ providedIn: 'root' })
export class HierarchyHelperService {
  flattenUnits(dto: Array<HierarchyDto>): Array<HierarchyDto> {
    return dto.reduce((acc, e) => {
      let childUnits = Array.isArray(e.children)
        ? this.flattenUnits(e.children?.map((c) => c.data))
        : [];

      childUnits = childUnits.map((e) => ({
        ...e,
        children: e.children.map((h) => ({ uId: h.uId })),
      }));

      return [...acc, e, ...childUnits] as HierarchyDto[];
    }, []);
  }
}
