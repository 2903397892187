import { Injectable, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { selectActiveSettings, selectAllSettings, selectCurrentSetting, selectLatestSettingForEachKeyForSettingGroupKey, selectLatestSettingForEachKeyForSettingGroupUId, selectLatestSettingForKey, selectLoadingGroups, selectLoadingMetadata, selectLoadingSettingKey, selectLoadingSettings, selectPendingSettings, selectPreviousSettings, selectSettingByUId, selectSettingGroup, selectSettingGroups, selectSettingsWithGroupUIdAndKeyKey, selectValueMetadataForGroup } from './key-value-setting.selectors';
import { fetchSetting, fetchSettingByGroupUIdAndKey, fetchSettingGroup, fetchSettingGroups, fetchSettings, fetchSingleValueMetadata, fetchValueMetadata, removeSettingByUId } from './key-value-setting.actions';

@Injectable({providedIn: 'root'})
export class KeyValueSettingStore {
    private store = inject(Store);

    allSettings$ = this.store.select(selectAllSettings);

    latestSettingForEachKeyForSettingGroupUId$ = (uId: string) => this.store.select(selectLatestSettingForEachKeyForSettingGroupUId(uId));

    latestSettingForEachKeyForSettingGroupKey$ = (key: string) => this.store.select(selectLatestSettingForEachKeyForSettingGroupKey(key))

    latestSettingForKey$ = (groupUId: string, key: string) => this.store.select(selectLatestSettingForKey(groupUId, key))

    currentSetting$ = (groupUId: string, key: string) => this.store.select(selectCurrentSetting(groupUId, key))

    activeSettings$ = (groupUIdOrKey: string, date: Date) => this.store.select(selectActiveSettings(groupUIdOrKey, date))

    pendingSettings$ = (groupUId: string, key: string) => this.store.select(selectPendingSettings(groupUId, key))

    previousSettings$ = (groupUId: string, key: string) => this.store.select(selectPreviousSettings(groupUId, key))

    valueMetadataForGroup$ = (groupUId: string) => this.store.select(selectValueMetadataForGroup(groupUId))

    settingGroup$ = (groupUId: string) => this.store.select(selectSettingGroup(groupUId));

    loadingGroups$ = this.store.select(selectLoadingGroups);

    loadingSettings$ = this.store.select(selectLoadingSettings);

    loadingSettingKey$ = (key: string) => this.store.select(selectLoadingSettingKey(key));

    loadingMetadata$ = this.store.select(selectLoadingMetadata);

    settingByUId$ = (uId: string) => this.store.select(selectSettingByUId(uId));

    settingsWithGroupUIdAndKeyKey$ = (groupUId: string, key: string) =>  this.store.select(selectSettingsWithGroupUIdAndKeyKey(groupUId, key));

    settingGroupByUId$ = (uId: string) => this.store.select(selectSettingGroup(uId));

    settingGroups$ = this.store.select(selectSettingGroups);

    fetchSettingGroups() {
        this.store.dispatch(fetchSettingGroups.request());
    }

    fetchSettingGroup(uId: string) {
        this.store.dispatch(fetchSettingGroup.request({uId}));
    }

    fetchSettings() {
        this.store.dispatch(fetchSettings.request());
    }

    fetchSetting(groupUId: string, uId: string) {
        this.store.dispatch(fetchSetting.request({groupUId, uId}));
    }

    fetchSettingWithKey(groupUId: string, key: string) {
        this.store.dispatch(fetchSettingByGroupUIdAndKey.request({groupUId, key }));
    }

    fetchMetadata() {
        this.store.dispatch(fetchValueMetadata.request());
    }

    fetchSingleValueMetadata(groupUId: string, uId: string) {
        this.store.dispatch(fetchSingleValueMetadata.request({groupUId, uId}));
    }


    removeSettingByUId(uId: string) {
        this.store.dispatch(removeSettingByUId({uId}));
    }

}
