import { Setting, SettingGroup, SettingValueMetadata } from "@graphql/hrp-settings";
import { EntityState, createEntityAdapter } from "@ngrx/entity";

export interface KeyValueSettingState {
    groups: EntityState<SettingGroup>;
    loadingGroups: boolean;
    metadata: EntityState<SettingValueMetadata>;
    loadingMetadata: boolean;
    settings: EntityState<Setting>;
    loadingSettings: boolean;
    loadingSettingKeys: Record<string, boolean>;
}

export const groupsAdapter = createEntityAdapter<SettingGroup>({
    selectId: (group) => group.uId,
    sortComparer: (a, b) => {
        if((a?.key || a?.name) && (b?.key || b?.name)) {
            return (a.key || a.name).localeCompare(b.key || b.name);
        }
        return 0;
    }
});

export const metadataAdapter = createEntityAdapter<SettingValueMetadata>({
    selectId: (metadata) => metadata.uId,
    sortComparer: (a, b) => {
        if((a?.key || a?.name) && (b?.key || b?.name)) {
            return (a.key || a.name).localeCompare(b.key || b.name);
        }
        return 0;
    }
});

export const settingsAdapter = createEntityAdapter<Setting>({
    selectId: (setting) => setting.uId,
    sortComparer: (a, b) => {
        if((a?.key || a?.name) && (b?.key || b?.name)) {
            return (a.key || a.name).localeCompare(b.key || b.name);
        }
        return 0;
    }
});

export const keyValueSettingInitialState: KeyValueSettingState = {
    groups: groupsAdapter.getInitialState(),
    loadingGroups: false,
    metadata: metadataAdapter.getInitialState(),
    loadingMetadata: false,
    settings: settingsAdapter.getInitialState(),
    loadingSettings: false,
    loadingSettingKeys: {},
};