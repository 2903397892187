import { ActionReducerMap } from '@ngrx/store';
import { AdministrationState } from './administration.state';
import { accountTypeReducer } from './account-type/account-type.reducer';
import { keyValueSettingReducer } from './key-value-setting/key-value-setting.reducer';
import { hierarchyReducer } from './hierachy/hierarchy.reducer';

export const administrationReducer: ActionReducerMap<AdministrationState> = {
  accountType: accountTypeReducer,
  keyValueSetting: keyValueSettingReducer,
  hierarchy: hierarchyReducer,
};
