import { Injectable, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { fetchAccountType, fetchAccountTypes } from './account-type.actions';
import {
  selectAccountTypes,
  selectAccountTypeById,
  selectLoadingList,
  selectAccountTypeLoadingById,
  selectAccountTypesWithKey,
  selectActiveAccountTypeForKey,
  selectPreviousAccountTypesForKey,
  selectUpcomingAccountTypesForKey,
  selectLatestAccountTypeForEachKey,
  selectLatestAccountTypeForKey,
  selectActiveAccountTypes
} from './account-type.selectors';

@Injectable({ providedIn: 'root' })
export class AccountTypeStore {
  private store = inject(Store);

  accountTypes$ = this.store.select(selectAccountTypes);

  accountTypesLoading$ = this.store.select(selectLoadingList);

  accountType$ = (uId: string) => this.store.select(selectAccountTypeById(uId));

  accountTypeLoading$ = (uId: string) =>
    this.store.select(selectAccountTypeLoadingById(uId));

  accountTypesWithKey$ = (key: string) =>
    this.store.select(selectAccountTypesWithKey(key));

  activeAccountTypes$ = this.store.select(selectActiveAccountTypes);

  activeAccountTypeForKey$ = (key: string) =>
    this.store.select(selectActiveAccountTypeForKey(key));

  previousAccountTypesForKey$ = (key: string) =>
    this.store.select(selectPreviousAccountTypesForKey(key));

  upcomingAccountTypesForKey$ = (key: string) =>
    this.store.select(selectUpcomingAccountTypesForKey(key));

  latestAccountTypeForEachKey$ = this.store.select(
    selectLatestAccountTypeForEachKey
  );

  latestAccountTypeForKey$ = (key: string) =>
    this.store.select(selectLatestAccountTypeForKey(key));

  fetchAccountTypes() {
    this.store.dispatch(fetchAccountTypes.request());
  }

  fetchAccountType(uId: string) {
    this.store.dispatch(fetchAccountType.request({ uId }));
  }
}
