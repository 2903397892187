import { HierarchyDto } from '@swagger/humanresources';

export class ValidationError extends Error {
  constructor(
    public readonly property: string,
    public readonly value: any,
    message: string
  ) {
    super(message);
  }
}

export class HierachyValidators {
  private constructor() {}

  static validateHierachyDto({
    hierachy,
    update,
  }: {
      hierachy: HierarchyDto;
    update?: boolean;
  }): void {
    if (!!update && !hierachy.uId) {
      throw new ValidationError(
        'hierachyDtoUid',
        hierachy?.uId,
        'Account Type uId is required'
      );
    }

    if (!hierachy?.key) {
      throw new ValidationError('key', hierachy?.key, 'Key is required');
    }

    if (!hierachy?.name) {
      throw new ValidationError('name', hierachy?.name, 'Name is required');
    }

    if (!hierachy?.type) {
      throw new ValidationError(
        'type',
        hierachy?.type,
        'Type is required'
      );
    }

    if (!hierachy?.start) {
      throw new ValidationError(
        'start',
        hierachy?.start,
        'Start Date is required'
      );
    }

    if (!hierachy?.stop) {
      throw new ValidationError(
        'stop',
        hierachy?.stop,
        'Stop Date is required'
      );
    }

    return null;
  }
}
