import { Injectable, inject } from '@angular/core';
import {
  AccountTypeDto,
  EntityStatus,
} from '@swagger/humanresources';
import { AccountTypeStore } from '@features/administration/store';
import { AccountTypeService as ApiService } from '@swagger/humanresources';
import { firstValueFrom } from 'rxjs';
import moment from 'moment';
import {
  validateAccountTypeDto,
} from './account-type.validation';

@Injectable({ providedIn: 'root' })
export class AccountTypeService {
  private store = inject(AccountTypeStore);

  private api = inject(ApiService);

  fetchAccountTypes = () => this.store.fetchAccountTypes();

  fetchAccountType = (uId: string) => this.store.fetchAccountType(uId);

  accountTypes$ = this.store.accountTypes$;

  accountTypesLoading$ = this.store.accountTypesLoading$;

  accountType$ = (uId: string) => this.store.accountType$(uId);

  accountTypeLoading$ = (uId: string) => this.store.accountTypeLoading$(uId);

  accountTypesWithKey$ = (key: string) => this.store.accountTypesWithKey$(key);

  latestAccountTypeForEachKey$ = this.store.latestAccountTypeForEachKey$;

  activeAccountTypes$ = this.store.activeAccountTypes$;

  activeAccountTypeForKey$ = (key: string) =>
    this.store.activeAccountTypeForKey$(key);

  previousAccountTypesForKey$ = (key: string) =>
    this.store.previousAccountTypesForKey$(key);

  upcomingAccountTypesForKey$ = (key: string) =>
    this.store.upcomingAccountTypesForKey$(key);

  /**
   * Create a new AccountTypeDto that can be modified
   * This method will not save the new AccountTypeDto to the server
   * @param save AccountTypeDto
   */
  createNew(): Promise<AccountTypeDto> {
    return Promise.resolve<AccountTypeDto>({
      start: moment().startOf('day').toJSON(),
    });
  }

  /**
   * Returns a copy of the AccountTypeDto that can be modified
   * This method will not save the new variant to the server
   * call save to save the new variant to the server
   * @param uId
   */
  async createNewVariant(key: string): Promise<AccountTypeDto> {

    const accountType = await firstValueFrom(
      this.store.latestAccountTypeForKey$(key)
    );

    if (!accountType) {
      throw new Error('Account Type not found');
    }

    return {
      uId: accountType.uId,
      key: accountType.key,
      name: accountType.name,
      description: accountType.description,
      quantityUnit: accountType.quantityUnit,
      quantityUnitType: accountType.quantityUnitType,
      start: moment().add(1, 'day').startOf('day').toJSON(),
    };
  }

  // async fixDate(uid: string) {
  //   const accountType = await firstValueFrom(this.accountType$(uid));

  //   if (!accountType) {
  //     throw new Error('Account Type not found');
  //   }

  //   await firstValueFrom(
  //     this.api.accountTypeUpdate({
  //       accountTypeUId: accountType.uId,
  //       body: { ...accountType, stop:  '2500-12-30T23:00:00Z' },
  //     })
  //   );
  // }

  /**
   * Saves the new variant to the server
   * @param accountType AccountTypeDto
   */
  async save(accountType: AccountTypeDto): Promise<AccountTypeDto> {
    validateAccountTypeDto(accountType);

    const isNewVariant = !!accountType.uId;

    accountType.start = moment(accountType.start).startOf('day').toJSON();
    accountType.stop = accountType.stop
      ? moment(accountType.stop).endOf('day').toJSON()
      : '2500-12-30T23:00:00Z';

    if (isNewVariant) {
      const currentAccountType = await firstValueFrom(
        this.accountType$(accountType.uId)
      );
      await firstValueFrom(
        this.api.accountTypeUpdate({
          accountTypeUId: accountType.uId,
          body: { ...currentAccountType, stop: accountType.start },
        })
      );
    }

    const response = await firstValueFrom(
      this.api.accountTypeCreate({ body: { ...accountType, uId: null } })
    );

    this.store.fetchAccountTypes();
    return response.result;
  }

  /**
   * Archives the AccountTypeDto on the server
   * @param key the uId of the AccountTypeDto to archive
   */
  async archive(key: string): Promise<AccountTypeDto> {
    const currentAccountType = await firstValueFrom(this.activeAccountTypeForKey$(key));

    const response = await firstValueFrom(
      this.api.accountTypeUpdate({
        accountTypeUId: currentAccountType.uId,
        body: {
          ...currentAccountType,
          status: EntityStatus.Archived,
          stop: moment().toJSON(),
        },
      })
    );
    this.store.fetchAccountTypes();
    return response.result;
  }
}
