import { Injectable } from '@angular/core';
import {
  EntityDtoContainerOfHierarchyDto,
  TouchedBase,
} from '@swagger/humanresources';
import { Observable, map } from 'rxjs';

type Entity = TouchedBase & {
  uId: string;
  children?: Array<EntityDtoContainerOfHierarchyDto>;
  parent?: EntityDtoContainerOfHierarchyDto;
  name?: string;
};

@Injectable({
  providedIn: 'root',
})
export class AbstractUnitService {
  getRootUnits(
    unitObservable$: Observable<Array<Entity>>
  ): Observable<Array<Entity>> {
    return unitObservable$.pipe(
      map((entities) =>
        entities.filter((unit) => {
          if (!unit.parent?.uId) {
            return true;
          }
          if (!entities.some((e) => e.uId === unit.parent?.uId)) {
            return true;
          }

          return false;
        })
      )
    );
  }

  flattenUnits(dto: Array<any>): Array<Entity> {
    return dto.reduce((acc, e) => {
      let childUnits = Array.isArray(e.children)
        ? this.flattenUnits(e.children?.map((c) => c.data))
        : [];

      childUnits = childUnits.map((e) => ({
        ...e,
        children: e.children.map((h) => ({ uId: h.uId })),
      }));
      return [...acc, e, ...childUnits] as Entity[];
    }, []);
  }
}
