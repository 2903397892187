import { Setting } from '@graphql/hrp-settings';
import moment from 'moment';

export function getSettingsBeforeSetting(
  settings: Setting[],
  setting: Setting
): Setting[] {
  return settings.filter((s) =>
    moment(s.validFrom).isBefore(setting.validFrom, 'day'), false
  );
}

export function getClosestSettingBeforeSetting(
  settings: Setting[],
  setting: Setting
): Setting {
  return getSettingsBeforeSetting(settings, setting)?.reduce((prev, curr) =>
    {
      if(!prev) return curr;
      return moment(prev.validFrom).isBefore(curr.validFrom, 'day') ? curr : prev;
    }, null
  );
}

export function getSettingsAfterSetting(
  settings: Setting[],
  setting: Setting
): Setting[] {
  return settings.filter((s) =>
    moment(s.validFrom).isAfter(setting.validFrom, 'day'), false
  );
}

export function getClosestSettingAfterSetting(
  settings: Setting[],
  setting: Setting
): Setting {
  return getSettingsAfterSetting(settings, setting)?.reduce((prev, curr) =>
    {
      if(!prev) return curr;
      return moment(prev.validFrom).isBefore(curr.validFrom, 'day') ? prev : curr
    }, null
  );
}
